import React, { useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { t } from 'i18next'
import { Text } from '@sitecore-jss/sitecore-jss-react'

import { CarStepSection } from '../../../../Foundation/components/CarStepSection'

import {
  getConfigurations,
  getContractLengthsById,
  getContractMileagesById,
  getColorsById,
  getOptionsByIdData
} from '../../selectors'
import { stepsActions } from '../../actions'
import detectScrollInside from '../../../../Foundation/hooks/detectScrollInside'

import { ActiveOptions } from './ActiveOptions'
import { TotalCost } from './TotalCost'

const CarSummary = ({
  fields: {
    datasourceItems: {
      CarSummary,
      Color: colorLabel,
      ContractPeriod,
      DeliveryTime,
      DeliveryTimeLong,
      DeliveryTimeShort,
      Insurance,
      InsuranceCompany,
      Mileage,
      NotSelected,
      Summary,
      TotalCost: totalCostLabel
    },
    name: carLabel
  }
}) => {
  const dispatch = useDispatch()
  const ref = useRef(null)

  useEffect(() => {
    dispatch(stepsActions.setRefForStep(3, ref))
  }, [])

  // set active if scroll inside div
  function setActiveStep() {
    dispatch(stepsActions.setActiveStep(3))
  }
  detectScrollInside(ref, setActiveStep)
  //

  const configurations = useSelector(getConfigurations)
  const contractLength = useSelector(
    getContractLengthsById(configurations.contractLength)
  )
  const contractMileage = useSelector(
    getContractMileagesById(configurations.contractMileage)
  )
  const color = useSelector(getColorsById(configurations.color))
  const optionsByIdData = useSelector(getOptionsByIdData)

  const contractLengthValue = contractLength?.description
  const contractMileageValue = contractMileage?.description

  const isInsuranceOption = configurations.optionsReadableIds.some(
    option => option.toLowerCase() === 'frskring'
  )

  const insuranceCompany = configurations.options.map(optionsId => {
    const { displayName } = optionsByIdData[optionsId]

    return displayName
  })

  console.log(configurations)

  const carSelectedInformationList = [
    {
      name: ContractPeriod.value,
      value: contractLengthValue
    },
    {
      name: Mileage.value,
      value: contractMileageValue
    },
    {
      name: DeliveryTime.value,
      value: configurations.isLongDelivery
        ? DeliveryTimeLong.value
        : DeliveryTimeShort.value
    }
  ]

  const carSelectedInformation = carSelectedInformationList.map(selected => {
    return (
      <div className="car-summary__description__text" key={selected.name}>
        {selected.name} {selected.value}
      </div>
    )
  })

  return (
    <CarStepSection refProp={ref}>
      <h1>
        {t('step')} 3 - <Text field={Summary} />
      </h1>
      <div className="car-summary__description">
        <img
          className="car-summary__description__image"
          src={color && color.image}
          alt={color}
        />
        <div className="car-summary__description__wrapper">
          <div className="car-summary__description__title">{carLabel}</div>
          <div className="car-summary__description__info">
            {carSelectedInformation}
          </div>
          <div
            className="car-summary__includes"
            dangerouslySetInnerHTML={{
              __html: CarSummary.value
            }}
          ></div>
        </div>
      </div>
      <div className="car-total">
        <div className="car-total__inner">
          <Text className="car-summary__box__title" tag="div" field={Summary} />
          <ActiveOptions
            configurations={configurations}
            colorLabel={colorLabel.value}
            carLabel={carLabel}
          />
          <TotalCost
            configurations={configurations}
            totalCostLabel={totalCostLabel}
            isInsuranceOption={isInsuranceOption}
            insuranceCompany={InsuranceCompany}
          />
        </div>
      </div>
    </CarStepSection>
  )
}

export default CarSummary
