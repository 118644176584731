import React from 'react'
import CarouselItem from './carouselItem'
import CarouselThumbnail from './carouselThumbnail'

class Carousel2 extends React.Component {
  render() {
    const id = this.props.rendering && this.props.rendering.uid
    const fullWidth =
      this.props.params && this.props.params.FullWidth ? 'full-width' : ''
    const marginTop =
      this.props.params && this.props.params.MarginTop ? 'mt-5' : ''
    const marginBottom =
      this.props.params && this.props.params.MarginBottom ? 'mb-5' : ''

    const hideIndicators = this.props.params && this.props.params.HideIndicators
    const thumbnailIndicators =
      this.props.params && this.props.params.ThumbnailIndicators

    const carouselItems =
      this.props.fields.items &&
      this.props.fields.items.map((carouselItem, index) => {
        return (
          <CarouselItem
            item={carouselItem}
            key={index}
            fullWidth={fullWidth}
            active={index === 0}
          />
        )
      })

    const carouselIndicators =
      !hideIndicators &&
      this.props.fields.items.map((carouselItem, index) => {
        return thumbnailIndicators ? (
          <CarouselThumbnail
            id={id}
            index={index}
            key={index}
            fields={carouselItem.fields}
            active={index === 0}
          />
        ) : (
          <li
            key={index}
            data-target={`#${id}`}
            data-slide-to={`${index}`}
            className={`${index === 0 ? 'active' : ''}`}
          ></li>
        )
      })

    return (
      <div className={`${fullWidth} ${marginBottom} ${marginTop}`}>
        <div
          id={id}
          style={{ maxHeight: '70vh' }}
          className={`carousel slide`}
          data-interval="false"
        >
          {!hideIndicators && !thumbnailIndicators && (
            <ol className="carousel-indicators">{carouselIndicators}</ol>
          )}
          <div className="carousel-inner">{carouselItems}</div>
          <a
            className="carousel-control-prev"
            href={`#${id}`}
            role="button"
            data-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="sr-only">Previous</span>
          </a>
          <a
            className="carousel-control-next"
            href={`#${id}`}
            role="button"
            data-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="sr-only">Next</span>
          </a>
        </div>
        {!hideIndicators && thumbnailIndicators && (
          <div className="container" style={{ transform: 'translateY(-50%)' }}>
            <div className="d-none d-md-flex align-items-center justify-content-center">
              {carouselIndicators}
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default Carousel2
