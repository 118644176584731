import { ALIGNS_CONTENT } from '../configs'

const lowerCaseFirstLetter = string => {
  return string.charAt(0).toLowerCase() + string.slice(1)
}

export const getAlignClass = align => {
  const isNewAlign = !!ALIGNS_CONTENT.includes(align)

  return isNewAlign ? lowerCaseFirstLetter(align) : ''
}
