import React from 'react'

const Divider = props => {
  let fullWidth = ''
  let marginTop = ''
  let marginBottom = ''

  if (props.params) {
    fullWidth = parseInt(props.params.FullWidth) === 1 ? 'full-width' : ''
    marginTop = parseInt(props.params.MarginTop) === 1 ? 'mt-5' : ''
    marginBottom = parseInt(props.params.MarginBottom) === 1 ? 'mb-5' : ''
  }

  return (
    <div
      className={`bg-primary ${fullWidth} ${marginTop} ${marginBottom}`}
      style={{ height: '5px' }}
    ></div>
  )
}

export default Divider
