import React from 'react'
import { FooterColumn } from './FooterColumn'
import { FooterLink } from './FooterLink'
import { Divider } from '../../../../Foundation/components/Divider'

const FordFooter = ({ fields }) => {
  const footerColumns = Object.keys(fields).map((fieldKey, i) => {
    const { title, text, links: linksField } = fields[fieldKey]

    const titleObj = { value: title }
    const textObj = { value: text }
    const links = linksField.map((link, i) => {
      const { url, description, external } = link

      return (
        <FooterLink
          key={i}
          url={url}
          description={description}
          isExternalLink={external}
        />
      )
    })

    return (
      <FooterColumn key={i} title={titleObj} text={textObj} links={links} />
    )
  })

  return (
    <>
      <Divider />
      <div className="footer">{footerColumns}</div>
    </>
  )
}

export default FordFooter
