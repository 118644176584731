import React from 'react'
import PropTypes from 'prop-types'

export const AccordionDetails = ({ children, expanded }) =>
  expanded ? (
    <div className="filter-mobile-accordion__details">{children}</div>
  ) : null

AccordionDetails.propTypes = {
  children: PropTypes.element.isRequired,
  expanded: PropTypes.bool.isRequired
}
