import React from 'react'
import PropTypes from 'prop-types'

export const AccordionSummary = ({ children, icon, onClick }) => (
  <div
    className="accordion__summary"
    role="button"
    tabIndex="0"
    onClick={onClick}
    onKeyPress={onClick}
  >
    {children}
    {icon}
  </div>
)

AccordionSummary.propTypes = {
  children: PropTypes.element.isRequired,
  icon: PropTypes.element.isRequired,
  onClick: PropTypes.func.isRequired
}
