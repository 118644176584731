import React from 'react'
import { STEPS } from '../../../configs'
import { Step } from './Step'

export const Steps = () => {
  const stepsJSX = STEPS.map(step => {
    return <Step key={step} title={step} />
  })

  return <div className="sticky-steps__steps">{stepsJSX}</div>
}
