import React from 'react'
import PropTypes from 'prop-types'

export const RadioButton = ({ checked, label, onClick }) => {
  const checkedClass = checked ? 'checked' : ''

  return (
    <div
      className="radio-button__wrapper"
      role="button"
      tabIndex="0"
      onKeyPress={onClick}
      onClick={onClick}
    >
      <div className={`radio-button ${checkedClass}`} />
      <div className="radio-button__text">{label}</div>
    </div>
  )
}

RadioButton.propTypes = {
  checked: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
}
