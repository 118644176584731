import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getRefForStep, getActiveStep } from '../../../../selectors'
import { stepsActions } from '../../../../actions'

export const Step = ({ title }) => {
  const dispatch = useDispatch()
  const stepRef = useSelector(getRefForStep(title))
  const activeStep = useSelector(getActiveStep)

  const activeClass =
    activeStep === title ? 'sticky-steps__rounded-step--active' : ''

  const handleClick = () => {
    dispatch(stepsActions.setActiveStep(title))

    const position = stepRef.current.getBoundingClientRect()

    if (title === 1) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    } else {
      window.scrollTo({
        left: position.left,
        top: position.top + window.scrollY - 104,
        behavior: 'smooth'
      })
    }
  }

  return (
    <div
      className={`sticky-steps__rounded-step ${activeClass}`}
      role="button"
      tabIndex="0"
      onKeyPress={handleClick}
      onClick={handleClick}
    >
      <div className="sticky-steps__rounded-title">
        <div className="sticky-steps__rounded-title__label">{title}</div>
      </div>
    </div>
  )
}
