import React from 'react'
import MultiSelect from 'react-multi-select-component'
import PropTypes from 'prop-types'
import { icons } from '../../../Project/ford-to-go/assets/icons'
import { OptionItem } from './OptionItem'

export const UsefulDropdown = ({
  label,
  options,
  selected,
  onChange,
  selectAllLabel
}) => {
  const customValueRenderer = () => label

  const customArrowRenderer = () => <icons.ArrowDown color="00095b" />

  const customItemRenderer = props => <OptionItem {...props} />

  return (
    <MultiSelect
      labelledBy={label}
      selectAllLabel={selectAllLabel}
      disableSearch
      options={options}
      value={selected}
      onChange={onChange}
      valueRenderer={customValueRenderer}
      ArrowRenderer={customArrowRenderer}
      ItemRenderer={customItemRenderer}
      ClearSelectedIcon={<></>}
    />
  )
}

UsefulDropdown.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    }).isRequired
  ).isRequired,
  selected: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    }).isRequired
  ).isRequired,
  onChange: PropTypes.func.isRequired
}
