export const FILTER_NAMES = ['name', 'streetAddress', 'city', 'zip']

export const getWeekDayKey = {
  Mon: 'MonmonOpenHours',
  Tue: 'tuesOpenHours',
  Wed: 'wedOpenHours',
  Thu: 'thursOpenHours',
  Fri: 'friOpenHours',
  Sat: 'satOpenHours',
  Sun: 'sunOpenHours'
}
