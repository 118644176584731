import React from 'react'
import PropTypes from 'prop-types'

export const AccordionSummary = ({ children, icon, onClick }) => (
  <div className="car-accordion__header">
    {children}
    <div
      className="car-accordion__icon"
      role="button"
      tabIndex="0"
      onKeyPress={onClick}
      onClick={onClick}
    >
      {icon}
    </div>
  </div>
)

AccordionSummary.propTypes = {
  children: PropTypes.element.isRequired,
  icon: PropTypes.element.isRequired,
  onClick: PropTypes.func.isRequired
}
