import React from 'react'

export const CheckMark = ({ color = '00095b' }) => (
  <svg
    width="14px"
    height="12px"
    viewBox="0 0 25 18"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>layer1</title>
    <g
      id="Startpage"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        id="Mobile-startpage-1-1"
        transform="translate(-33.000000, -927.000000)"
        stroke={`#${color}`}
        strokeWidth="2"
      >
        <g id="Group" transform="translate(34.000000, 926.000000)">
          <g id="checkIcon" transform="translate(0.000000, 2.000000)">
            <polyline id="layer1" points="0 6.9 8.05 15.333 23 0" />
          </g>
        </g>
      </g>
    </g>
  </svg>
)
