import React from 'react'
import { t } from 'i18next'
import NumberFormat from 'react-number-format'

export const CarSummaryItem = ({ title, price }) => (
  <div className="car-total__item car-total__item--table">
    <div className="car-total__name">{title}</div>
    <div className="car-total__price">
      <NumberFormat
        value={price}
        thousandSeparator={' '}
        displayType={'text'}
      />{' '}
      {t('currency_per_month')}
    </div>
  </div>
)
