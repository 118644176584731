export const getResellersIds = state => {
  return state.resellers.data.allIds
}

export const getResellersByIds = state => {
  return state.resellers.data.byId
}

export const getSearchText = state => {
  return state.resellers.searchText
}

export const getCurrentResellers = state => {
  return state.resellers.currentResellers
}

export const getSelectedResellerId = state => {
  return state.resellers.selectedResellerId
}
