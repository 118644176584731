import React from 'react'
import { useSelector } from 'react-redux'
import { highlightSearchWord } from '../../../helpers'
import { getResellersByIds } from '../../../selectors'

export const SearchSuggestions = ({
  filteredResellers,
  filterName,
  searchText,
  handleClickSearchSuggestion
}) => {
  const resellersById = useSelector(getResellersByIds)

  if (!searchText) return <></>

  return filteredResellers.slice(0, 5).map(resellerId => {
    const reseller = resellersById[resellerId]
    const text = reseller[filterName]

    const {
      firstPartOfText,
      secondPartOfText,
      thirdPartOfText
    } = highlightSearchWord(text, searchText)

    return (
      <div
        key={reseller.id}
        id={reseller.id}
        className="reseller-search-suggestion"
        role="button"
        tabIndex="0"
        onKeyPress={e => handleClickSearchSuggestion(e)}
        onClick={e => handleClickSearchSuggestion(e)}
      >
        {firstPartOfText}
        <span className="reseller-search-suggestion__bold-text">
          {secondPartOfText}
        </span>
        {thirdPartOfText}
      </div>
    )
  })
}
