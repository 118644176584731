import React from 'react'
import { Image, Text } from '@sitecore-jss/sitecore-jss-react'

class CarouselItem extends React.Component {
  render() {
    const background = {
      backgroundImage: `url(${this.props.item.fields.Image.value.src})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover'
    }

    return (
      <div
        className={`carousel-item ${this.props.active ? 'active' : ''}`}
        style={background}
      >
        {this.props.fullWidth ? (
          <div
            className="d-flex align-items-end"
            style={{ backgroundColor: 'rgba(0, 0, 0, 0.4)', minHeight: '70vh' }}
          >
            <div className="container">
              <div
                className="jumbotron jumbotron-fluid mb-5 text-white"
                style={{ background: 'none' }}
              >
                <Text
                  tag="h1"
                  field={this.props.item.fields.Title}
                  className="text-uppercase"
                />
                <Text
                  tag="p"
                  field={this.props.item.fields.Text}
                  className="lead"
                />
              </div>
            </div>
          </div>
        ) : (
          <Image
            field={this.props.item.fields.Image}
            className="d-block w-100 img-fluid"
          />
        )}
      </div>
    )
  }
}

export default CarouselItem
