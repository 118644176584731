import React from 'react'
import PropTypes from 'prop-types'
import { icons } from '../../../../Project/ford-to-go/assets/icons'

export const OptionItem = ({ checked, onClick, option }) => (
  <div
    className="option-item"
    role="button"
    tabIndex="0"
    onKeyPress={onClick}
    onClick={onClick}
  >
    <div className="option-item__text">{option.label}</div>
    {checked && <icons.CheckMark />}
  </div>
)

OptionItem.propTypes = {
  checked: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  option: PropTypes.shape({
    label: PropTypes.string.isRequired
  }).isRequired
}
