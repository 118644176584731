import React from 'react'
import PropTypes from 'prop-types'
import { icons } from '../../../Project/ford-to-go/assets/icons'

export const Chip = ({ label, onDelete }) => {
  const handleDelete = () => {
    onDelete(label)
  }

  return (
    <div
      className="chip"
      role="button"
      tabIndex="0"
      onKeyPress={handleDelete}
      onClick={handleDelete}
    >
      <div className="chip__label">{label}</div>
      <div className="chip__icon">
        <icons.SmallCross />
      </div>
    </div>
  )
}

Chip.propTypes = {
  label: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired
}
