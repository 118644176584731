import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Chip } from '../../../../../../../Foundation/components/Chip'
import { carListActions } from '../../../../../actions'
import { getSelectedFiltersKeys } from '../../../../../helpers'

export const Chips = () => {
  const dispatch = useDispatch()
  const carFiltersSelected = useSelector(
    state => state.carList.carFiltersSelected
  )

  const handleDelete = label => {
    dispatch(carListActions.removeCarsFilter(label))
  }

  const chips = getSelectedFiltersKeys(carFiltersSelected)
    .map(key =>
      carFiltersSelected[key].map(value => (
        <Chip key={value} label={value} onDelete={handleDelete} />
      ))
    )
    .reduce((acc, curr) => acc.concat(curr), [])

  return chips.length > 0 ? <div className="filter__chips">{chips}</div> : null
}
