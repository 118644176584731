import React from 'react'
import PropTypes from 'prop-types'

export const Title = ({ children }) => {
  return <div className="car-option-subtitle">{children}</div>
}

Title.propTypes = {
  children: PropTypes.node.isRequired
}
