import { carListTypes } from '../types'

export const carListActions = Object.freeze({
  setCarList: fields => dispatch => {
    return dispatch({
      type: carListTypes.SET_CAR_LIST,
      payload: fields
    })
  },
  updateCarsFilter: (type, options) => dispatch => {
    return dispatch({
      type: carListTypes.UPDATE_CARS_FILTER,
      payload: {
        type,
        options
      }
    })
  },
  updatePriceFilter: price => dispatch => {
    return dispatch({
      type: carListTypes.UPDATE_PRICE_FILTER,
      payload: price
    })
  },
  removeCarsFilter: label => dispatch => {
    return dispatch({
      type: carListTypes.REMOVE_CARS_FILTER,
      payload: label
    })
  },
  sortCars: sort => dispatch => {
    return dispatch({
      type: carListTypes.SORT_CARS,
      payload: sort
    })
  },
  setQuantityCars: quantity => dispatch => {
    return dispatch({
      type: carListTypes.SET_QUANTITY_CARS,
      payload: quantity
    })
  }
})
