import React from 'react'

export const SmallCross = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10px"
    height="10px"
    viewBox="19.999000549316406 20 22 22.001998901367188"
    aria-labelledby="title"
    aria-describedby="desc"
    role="img"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Close</title>
    <desc>A line styled icon from Orion Icon Library.</desc>
    <path
      data-name="layer1"
      fill="none"
      stroke="#fff"
      strokeMiterlimit="10"
      strokeWidth="2"
      d="M41.999 20.002l-22 22m22 0L20 20"
      strokeLinejoin="round"
      strokeLinecap="round"
    />
  </svg>
)
