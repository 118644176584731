import React, { Fragment } from 'react'
import { withRouter, NavLink } from 'react-router-dom'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import { loader as gqlLoader } from 'graphql.macro'
import GraphQLData from '../../../../Foundation/GraphQL/GraphQLData'

// Component used on KWAuto & Carstore.
// In Sitecore version 9.2, special characters (&...) are not allowed in "displayName". So we need to "dangerouslySetInnerHtml" here.

const NavigationLinks = props => {
  const textColor =
    props.params && props.params.LightNavbar ? 'text-secondary' : 'text-white'
  const backgroundColor =
    props.params && props.params.LightNavbar ? 'bg-light' : 'bg-secondary'

  let outputNavigation = ''

  const { item } = props.navigationQuery

  if (!item) {
    return null
  }

  const filteredRoutes = item.children.filter(route => {
    return route.template.name === 'App Route'
  })

  // Loop through parent routes.
  outputNavigation = filteredRoutes.map((route, index) => {
    // Loop through subroutes.
    const subRoutes = route.children.filter(child => {
      return child.template.name === 'App Route'
    })

    // If parent route has no subroutes.
    if (!Array.isArray(subRoutes) || !subRoutes.length) {
      // If "hideInNavigation" is enabled on parent route.
      if (route.hideInNavigation.jss.value === true) {
        return null
      }

      // If user is on a route and clicks on the same route. We want to reset the states on the current route.
      // So that it acts like a "refresh".
      const linkTarget = {
        pathname: `/${route.name}`,
        key: Date.now(),
        state: {
          applied: true,
          prevPath: props.location.pathname
        }
      }

      // If route has "displayName" - else print "name"
      return (
        <li className="nav-item" key={index}>
          {route.displayName ? (
            <NavLink
              to={linkTarget}
              className={`nav-link ${textColor} text-uppercase`}
            >
              <span
                dangerouslySetInnerHTML={{ __html: route.displayName }}
              ></span>
            </NavLink>
          ) : (
            <NavLink
              to={linkTarget}
              className={`nav-link ${textColor} text-uppercase`}
            >
              {route.name.split('-').join(' ')}
            </NavLink>
          )}
        </li>
      )
    } else {
      const disabledSubRouteLinks = []

      // Loop through subroutes only.
      const outputDropdown = subRoutes.map((subroute, index) => {
        if (subroute.hideInNavigation.jss.value === true) {
          // Push all disabled subroutes to array.
          disabledSubRouteLinks.push(subroute)
          return null
        }

        return (
          <Fragment key={index}>
            {subroute.displayName ? (
              <NavLink
                className={`dropdown-item ${textColor} text-uppercase`}
                to={`/${route.name}/${subroute.name}`}
                key={index}
              >
                <span
                  dangerouslySetInnerHTML={{ __html: subroute.displayName }}
                ></span>
              </NavLink>
            ) : (
              <NavLink
                className={`dropdown-item ${textColor} text-uppercase`}
                to={`/${route.name}/${subroute.name}`}
                key={index}
              >
                {subroute.name.split('-').join(' ')}
              </NavLink>
            )}
          </Fragment>
        )
      })

      // If "hideInNavigation" is enabled on main route - Hide main route including subroutes.
      if (route.hideInNavigation.jss.value === true) {
        return null
      }

      // If "hideInNavigation" is enabled on all subroutes but not on the main route - Print main route as "Link".
      if (disabledSubRouteLinks.length === outputDropdown.length) {
        // If user is on a route and clicks on the same route. We want to reset the states on the current route.
        // So that it acts like a "refresh".
        const linkTarget = {
          pathname: `/${route.name}`,
          key: Date.now(),
          state: {
            applied: true,
            prevPath: props.location.pathname
          }
        }

        // If route has "displayName" on Sitecore - else print "name"
        return (
          <li className="nav-item" key={index}>
            {route.displayName ? (
              <NavLink
                to={linkTarget}
                className={`nav-link ${textColor} text-uppercase`}
                key={index}
              >
                <span
                  dangerouslySetInnerHTML={{ __html: route.displayName }}
                ></span>
              </NavLink>
            ) : (
              <NavLink
                to={linkTarget}
                className={`nav-link ${textColor} text-uppercase`}
                key={index}
              >
                {route.name.split('-').join(' ')}
              </NavLink>
            )}
          </li>
        )
      } else {
        return (
          <li className="nav-item dropdown" key={index}>
            <p
              className={`nav-link ${textColor} text-uppercase m-0`}
              id="navbarDropdown"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {route.displayName ? (
                <span
                  dangerouslySetInnerHTML={{ __html: route.displayName }}
                ></span>
              ) : (
                route.name.split('-').join(' ')
              )}
            </p>
            <ul
              className={`dropdown-menu ${backgroundColor}`}
              aria-labelledby="navbarDropdown"
            >
              {outputDropdown}
            </ul>
          </li>
        )
      }
    }
  })

  // Not sure if this is used on KWAUTO? Not using it on Carstore.
  return props.params && props.params.DynamicNavigation ? (
    <nav
      className={`full-width ${backgroundColor}`}
      style={{ overflow: 'visible' }}
    >
      <ul className="navbar list-unstyled float-right my-0 mr-1">
        {outputNavigation}
      </ul>
    </nav>
  ) : (
    <ul className="navbar-nav">{outputNavigation}</ul>
  )
}

// Fetch routes.
const GET_NAVIGATION_ROUTES_QUERY = gqlLoader('./query.graphql')

export default withSitecoreContext()(({ sitecoreContext, params }) => {
  const navigationStartItemPath =
    params && params.DynamicNavigation
      ? `${sitecoreContext.itemId}`
      : `/sitecore/content/${sitecoreContext.site.name}/home`
  const WrappedComponent = GraphQLData(GET_NAVIGATION_ROUTES_QUERY, {
    name: 'navigationQuery',
    options: {
      variables: {
        navigationStartItemPath: navigationStartItemPath,
        lang: sitecoreContext.language
      }
    }
  })(withRouter(NavigationLinks))

  return <WrappedComponent params={params} sitecoreContext={sitecoreContext} />
})
