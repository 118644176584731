import { icons } from '../assets/icons'

export const DROPDOWN_DATA = [
  { label: 'Modell', type: 'brand' },
  { label: 'Biltyp', type: 'carType' },
  { label: 'Drivmedel', type: 'fuel' },
  { label: 'Växellåda', type: 'gearBox' }
]

export const sortOptions = {
  up: 'down',
  down: 'up'
}

export const sortArrow = {
  up: icons.SortArrowUp,
  down: icons.SortArrowDown
}
