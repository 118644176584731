import React from 'react'
import { useSelector } from 'react-redux'
import { t } from 'i18next'
import { Text } from '@sitecore-jss/sitecore-jss-react'

import { getActiveStep } from '../../../selectors'

export const StepLabel = ({ translations }) => {
  const activeStep = useSelector(getActiveStep)

  function getStepsLabel() {
    switch (activeStep) {
      case 1:
        return <Text field={translations.Information} />
      case 2:
        return <Text field={translations.Configure} />
      case 3:
        return <Text field={translations.Summary} />
      case 4:
        return <Text field={translations.Order} />
      default:
      // do nothing
    }
  }

  return (
    <div className="sticky-steps__section-title">
      <div className="sticky-steps__active-number">
        {t('step')} {activeStep}
      </div>{' '}
      <div className="sticky-steps__active-label"> {getStepsLabel()}</div>
    </div>
  )
}
