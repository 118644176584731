import React from 'react'
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react'

const RichContentBlock = ({ fields, params }) => {
  const marginTop = params && params.MarginTop ? 'mt-5' : ''
  const marginBottom = params && params.MarginBottom ? 'mb-5' : ''

  return (
    <div className={`content-block ${marginTop} ${marginBottom}`}>
      <Text tag="h2" field={fields.Title} />
      <RichText field={fields.Text} />
    </div>
  )
}

export default RichContentBlock
