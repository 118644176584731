import React from 'react'
import InputRange from 'react-input-range'
import PropTypes from 'prop-types'
import 'react-input-range/lib/css/index.css'

export const UsefulInputRange = ({ value, maxValue, onChange }) => (
  <InputRange
    formatLabel={() => ''}
    maxValue={maxValue}
    minValue={1}
    step={100}
    value={value}
    onChange={onChange}
  />
)

UsefulInputRange.propTypes = {
  value: PropTypes.number.isRequired,
  maxValue: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired
}
