import React from 'react'
import PropTypes from 'prop-types'

export const Dot = ({ isSelected, onClick }) => {
  return (
    <div
      className={`carousel__dot ${isSelected ? 'select' : ''}`}
      role="button"
      aria-label="Click"
      tabIndex="0"
      onClick={onClick}
      onKeyPress={onClick}
    />
  )
}

Dot.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
}
