import { resellersTypes, googleMapTypes } from '../types'

const initialState = {
  data: {
    byId: {},
    allIds: []
  },
  searchText: '',
  currentResellers: [],
  selectedResellerId: '',
  noResellersFound: false,
  googleMap: {
    key: '',
    zoom: 10,
    center: {
      lat: 59.8404586,
      lng: 17.6390836
    }
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case resellersTypes.SET_RESELLERS:
      const resellers = action.payload

      const normalizedResellers = {
        byId: {},
        allIds: []
      }

      resellers.forEach(reseller => {
        normalizedResellers.allIds.push(reseller.id)

        normalizedResellers.byId[reseller.id] = reseller
      })

      return {
        ...state,
        data: normalizedResellers
      }
    case googleMapTypes.SET_GOOGLE_KEY:
      return {
        ...state,
        googleMap: {
          ...state.googleMap,
          key: action.payload
        }
      }
    case resellersTypes.SEARCH_RESELLERS:
      return {
        ...state,
        searchText: action.payload
      }
    case googleMapTypes.SET_CENTER_MAP:
      return {
        ...state,
        googleMap: {
          ...state.googleMap,
          center: action.payload
        }
      }
    case googleMapTypes.SET_ZOOM_MAP:
      return {
        ...state,
        googleMap: {
          ...state.googleMap,
          zoom: action.payload
        }
      }
    case resellersTypes.SET_CURRENT_RESELLERS:
      return {
        ...state,
        currentResellers: action.payload,
        selectedResellerId: action.payload[0].resellerId,
        searchText: ''
      }
    case resellersTypes.SELECT_RESELLER:
      return {
        ...state,
        selectedResellerId: action.payload
      }
    case resellersTypes.RESET_SELECTED_RESELLER:
      return {
        ...state,
        selectedResellerId: '',
        noResellersFound: action.payload.isError
      }
    default:
      return state
  }
}
