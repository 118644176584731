import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Text } from '@sitecore-jss/sitecore-jss-react'
import PropTypes from 'prop-types'
import { t } from 'i18next'

import {
  getColorsById,
  getSelectedColorId,
  getConfigurations
} from '../../../../selectors'
import { getColorStyle } from '../../../../helpers'
import { carOptionsActions } from '../../../../actions'

export const ColorItem = ({ id }) => {
  const dispatch = useDispatch()
  const {
    displayName,
    colorCode,
    colorStyle,
    variantId,
    prices,
    longDelivery
  } = useSelector(getColorsById(id))

  const selectedColorId = useSelector(getSelectedColorId)

  const displayNameObj = { value: displayName }
  const colorStyleObj = getColorStyle(colorCode, colorStyle)
  const selectedClass = selectedColorId === variantId ? 'selected' : ''

  const handleClick = () => {
    dispatch(
      carOptionsActions.setCarSelectedOption({
        color: variantId
      })
    )
  }

  const configurations = useSelector(getConfigurations)
  return (
    <div
      className="car-colors__choice"
      role="button"
      tabIndex="0"
      onKeyPress={handleClick}
      onClick={handleClick}
    >
      <div className={`car-colors__header ${selectedClass}`}>
        <div style={colorStyleObj} className="car-colors__color-circle" />
      </div>
      <div className="car-colors__footer">
        <Text className="car-colors__name" field={displayNameObj} tag="div" />
        <div className="car-colors__price">
          +
          {
            prices.find(
              item => item.duration === configurations.contractLengthSize
            )?.price
          }{' '}
          {t('currency')}
        </div>
      </div>
    </div>
  )
}

ColorItem.propTypes = {
  id: PropTypes.string.isRequired
}
