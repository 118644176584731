import { useEffect } from 'react'

export default function detectScrollInside(ref, func) {
  const handleScroll = () => {
    if (ref.current) {
      const top = ref.current.getBoundingClientRect().top
      const bottom = ref.current.getBoundingClientRect().bottom

      if (top <= 300 && bottom >= 300) {
        func()
      }
    }
  }

  useEffect(() => {
    // debounce to avoid spam
    let debounceTimeoutId

    window.addEventListener('scroll', function() {
      clearTimeout(debounceTimeoutId)
      debounceTimeoutId = setTimeout(() => handleScroll(), 300)
    })
  }, [])
}
