import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { Text } from '@sitecore-jss/sitecore-jss-react'

import { Input } from '../../../../Foundation/components/Input'
import { Button } from '../../../../Foundation/components/Button'
import { UsefulCheckbox } from '../../../../Foundation/components/Checkbox'
import { CarStepSection } from '../../../../Foundation/components/CarStepSection'

import {
  getCarId,
  getSelectedColorId,
  getColorProductId,
  getSelectedContractLength,
  getContractLengthProductId,
  getSelectedContractMileage,
  getContractMileageProductId,
  getSelectedOptionsData,
  getSelectedResellerId
} from '../../selectors'
import { createProductObject, createReadableProductObject } from '../../helpers'

const checkboxlabelStyle = {
  marginLeft: 8,
  fontSize: 16,
  color: '#6d6d6d',
  letterSpacing: 0.78
}

const CarCheckoutFor = ({ fields }) => {
  const carId = useSelector(getCarId)
  const colorId = useSelector(getSelectedColorId)
  const colorProductId = useSelector(getColorProductId)
  const contractLengthId = useSelector(getSelectedContractLength)
  const contractLengthProductId = useSelector(getContractLengthProductId)
  const contractMileageId = useSelector(getSelectedContractMileage)
  const contractMileageProductId = useSelector(getContractMileageProductId)
  const selectedOptions = useSelector(getSelectedOptionsData)
  const selectedResellerId = useSelector(getSelectedResellerId)
  const reduxState = useSelector(state => state)

  const [sendOrderError, setSendOrderError] = useState('')

  const [error, setError] = useState({})
  const [form, setForm] = useState({ email: '', phoneNumber: '' })
  const [checkedPolicy1, setPolicy1] = useState(false)
  const [checkedPolicy2, setPolicy2] = useState(false)
  const [submitIsLoading, setSubmitIsLoading] = useState(false)

  const onSubmit = async event => {
    event.preventDefault()

    setSubmitIsLoading(true)

    const mailformat = /^([a-zA-Z0-9]+([.][a-zA-Z0-9]+)*@\w+([.]\w+)*\.\w+([.]\w+)*)$/

    if (!form.email.match(mailformat)) {
      setError(prevState => ({
        ...prevState,
        email: fields.NotValidEmail.value
      }))
    }

    if (form.email.match(mailformat) && form.phoneNumber !== '') {
      const car = createProductObject(carId, 'CAR')
      const color = createProductObject(colorProductId, 'COLOR', colorId)
      const length = createProductObject(
        contractLengthProductId,
        'CONTRACT',
        contractLengthId
      )
      const mileage = createProductObject(
        contractMileageProductId,
        'CONTRACT',
        contractMileageId
      )
      const options = selectedOptions.map(selectedOption => {
        const { productId, variantId } = selectedOption

        return createProductObject(productId, 'OPTION', variantId)
      })

      const products = [car, color, length, mileage, ...options]

      const order = {
        products,
        phone: form.phoneNumber,
        email: form.email,
        resellerId: selectedResellerId
      }

      setSendOrderError('')

      await axios
        .post(`/apicommerce/ford/SendOrder`, order)
        .then(res => {
          const { url, errorMessage } = res.data.response

          if (url) {
            const readableProductObject = createReadableProductObject(
              reduxState
            )

            window.dataLayer.push({
              event: 'initiatePurchase',
              ...readableProductObject
            })

            window.location.replace(url)
          } else {
            setSendOrderError(errorMessage)
          }
        })
        .catch(err => {
          setSendOrderError(
            `Något gick fel, vänligen kontakta administratören.`
          )
        })
    }

    setSubmitIsLoading(false)
  }

  const handleChange = event => {
    const { name, value } = event.target

    setForm(prevState => ({ ...prevState, [name]: value }))

    setError({})
  }

  const isSubmitFormDisabled =
    !form.email || !form.phoneNumber || !checkedPolicy1 || !checkedPolicy2

  return (
    <CarStepSection noBorder noMarginTop>
      <div className={`fill-order-credentials`}>
        {!selectedResellerId && <div className="overlay-disabled"></div>}
        <Text
          field={fields.Order}
          className="fill-order-credentials__title"
          tag="p"
        />
        <Text
          field={fields.FillYourCredentials}
          className="fill-order-credentials__subtitle"
          tag="p"
        />
        <form className="fill-order-credentials__form">
          <div className="fill-order-credentials__input-group">
            <Input
              value={form.phoneNumber}
              name="phoneNumber"
              placeholder={`${fields.PhoneNumber.value} *`}
              onChange={handleChange}
              error={error.phoneNumber}
            />
          </div>
          <div className="fill-order-credentials__input-group">
            <Input
              value={form.email}
              name="email"
              placeholder={`${fields.Email.value} *`}
              onChange={handleChange}
              error={error.email}
            />
          </div>
          <div>
            <div className="fill-order-credentials__checkbox-group">
              <UsefulCheckbox
                checked={checkedPolicy1}
                label={fields.CheckboxText1.value}
                labelStyle={checkboxlabelStyle}
                onChange={() => setPolicy1(!checkedPolicy1)}
              />
            </div>
            <div className="fill-order-credentials__checkbox-group">
              <UsefulCheckbox
                checked={checkedPolicy2}
                label={fields.CheckboxText2.value}
                labelStyle={checkboxlabelStyle}
                onChange={() => setPolicy2(!checkedPolicy2)}
              />
            </div>
            <div className="fill-order-credentials__button-group">
              {' '}
              <Button
                text={fields.OrderButtonLabel.value}
                onClick={onSubmit}
                border
                disabled={isSubmitFormDisabled}
              />
            </div>
            {sendOrderError && (
              <p style={{ color: 'crimson', marginBottom: '32px' }}>
                {sendOrderError}
              </p>
            )}
            <p
              className="fill-order-credentials__gdpr-info"
              dangerouslySetInnerHTML={{ __html: fields.GDPRText.value }}
            ></p>
          </div>
        </form>
      </div>

      {submitIsLoading && (
        <>
          <div className="overlay-disabled overlay-disabled--full-screen">
            {' '}
            <div
              className="spinner-border"
              style={{
                width: '3rem',
                height: '3rem',
                color: '#00095b'
              }}
              role="status"
            ></div>
          </div>
        </>
      )}
    </CarStepSection>
  )
}

export default CarCheckoutFor
