import React from 'react'
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import RoutableSitecoreLink from '../../../utils/RoutableSitecoreLink'

export const NavigationLink = ({ label, externalTarget, url }) => {
  const location = useLocation()

  const isActiveUrl = location.pathname === url

  return (
    <div className="navigation__item">
      <RoutableSitecoreLink
        to={url}
        externalTarget={externalTarget}
        className={`navigation__item__link ${isActiveUrl ? 'active' : ''}`}
      >
        {label}
      </RoutableSitecoreLink>
    </div>
  )
}

NavigationLink.propTypes = {
  label: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired
}
