import React from 'react'
import { Text } from '@sitecore-jss/sitecore-jss-react'
import { Header, Description } from '../components'

export const TextComponent = ({ alignText, header, description }) => {
  return (
    <div className="image-text__wrapper">
      <Header align={alignText}>
        <Text field={header} />
      </Header>
      <Description align={alignText}>
        <Text field={description} />
      </Description>
    </div>
  )
}
