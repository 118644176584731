import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Divider } from '../../../../../Foundation/components/Divider'
import { useWindowSize } from '../../../../../Foundation/hooks/useWindowSize'

import { icons } from '../../../assets/icons'

import { carListActions } from '../../../actions'
import { getCarsAllIds, getQuantityCars } from '../../../selectors'

export const ShowMore = () => {
  const dispatch = useDispatch()

  const totalCarsQuantity = useSelector(getCarsAllIds).length
  const showingCarsQuantity = useSelector(getQuantityCars)

  const { width } = useWindowSize()

  const handleClick = () => {
    dispatch(carListActions.setQuantityCars(6))
  }

  if (showingCarsQuantity >= totalCarsQuantity) return <></>

  return (
    <div className="cards__more">
      {width >= 768 && <Divider color="grey" />}
      <div
        className="cards__more__label"
        role="button"
        tabIndex="0"
        onKeyPress={handleClick}
        onClick={handleClick}
      >
        <div className="cards__more__label__text">Visa fler bilar</div>
        <icons.ArrowDown />
      </div>
      {width >= 768 && <Divider color="grey" />}
    </div>
  )
}
