import React from 'react'
import PropTypes from 'prop-types'
import { UsefulCarousel } from '../../../../Foundation/components/Carousel'

const HeroCarousel = ({ fields }) => (
  <div className="full-width">
    <UsefulCarousel items={fields.HeroList} large />{' '}
  </div>
)

HeroCarousel.propTypes = {
  fields: PropTypes.shape({
    HeroList: PropTypes.arrayOf(
      PropTypes.shape({
        fields: PropTypes.shape({
          Image: PropTypes.shape({
            value: PropTypes.shape({
              src: PropTypes.string.isRequired,
              alt: PropTypes.string.isRequired
            }).isRequired
          }).isRequired,
          AlignText: PropTypes.shape({
            value: PropTypes.string
          }).isRequired,
          Title: PropTypes.shape({
            value: PropTypes.string
          }).isRequired,
          Title2: PropTypes.shape({
            value: PropTypes.string
          }).isRequired,
          Text: PropTypes.shape({
            value: PropTypes.string
          }).isRequired,
          PriceText: PropTypes.shape({
            value: PropTypes.string
          }).isRequired,
          Link: PropTypes.shape({
            href: PropTypes.string,
            text: PropTypes.string
          }).isRequired
        }).isRequired
      }).isRequired
    ).isRequired
  }).isRequired
}

export default HeroCarousel
