import { carOptionsTypes } from '../types'

export const carOptionsActions = Object.freeze({
  setCarOptions: fields => dispatch => {
    return dispatch({
      type: carOptionsTypes.SET_CAR_OPTIONS,
      payload: fields
    })
  },
  setCarSelectedOption: option => dispatch => {
    return dispatch({
      type: carOptionsTypes.SET_CAR_SELECTED_OPTION,
      payload: option
    })
  },
  setUserMadeOrder: refId => dispatch => {
    return dispatch({
      type: carOptionsTypes.SET_USER_MADE_ORDER,
      payload: refId
    })
  }
})
