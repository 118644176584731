import React from 'react'

export const Hamburger = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="26"
    viewBox="0 0 26 26"
  >
    <rect width="32" height="2" fill="#00095b" />
    <rect y="12" width="32" height="2" fill="#00095b" />
    <rect y="24" width="32" height="2" fill="#00095b" />
  </svg>
)
