import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { resellersActions } from '../../../actions'
import {
  getResellersByIds,
  getResellersIds,
  getSearchText
} from '../../../selectors'
import { Input } from '../../../../../Foundation/components/Input'
import { icons } from '../../../assets/icons'
import {
  fitBounds,
  findNearestMarkers,
  getPointFromGeocode
} from '../../../helpers/googleMap.js'

export const SearchInput = ({ googleRef, googleMapRef, translations }) => {
  const dispatch = useDispatch()
  const resellersIds = useSelector(getResellersIds)
  const resellersById = useSelector(getResellersByIds)
  const searchText = useSelector(getSearchText)

  const handleInputChange = e => {
    dispatch(resellersActions.searchResellers(e.target.value))
  }

  const handleClick = async event => {
    event.preventDefault()
    const copyResellersIds = [...resellersIds]
    const currentGoogleRef = googleRef.current
    const currentGoogleMapRef = googleMapRef.current

    const geocoder = new currentGoogleRef.Geocoder()

    const address = `${searchText}, Sweden`

    const { results } = await geocoder.geocode(
      { address },
      (result, status) => {
        if (status === currentGoogleRef.GeocoderStatus.OK) {
          dispatch(resellersActions.resetSelectedReseller(false))
          return result
        } else {
          dispatch(resellersActions.resetSelectedReseller(true))
        }
      }
    )

    const point = getPointFromGeocode(results)
    const markers = findNearestMarkers(copyResellersIds, resellersById, point)

    fitBounds(currentGoogleRef, currentGoogleMapRef, markers)

    dispatch(resellersActions.setCurrentResellers(markers))
    dispatch(resellersActions.searchResellers(''))
  }

  return (
    <div className="reseller-input">
      <form>
        <Input
          value={searchText}
          placeholder={translations.SearchPlaceholderLabel.value}
          onChange={handleInputChange}
        />
        <button
          className="reseller-input__search-btn"
          tabIndex="0"
          onClick={handleClick}
        >
          <icons.Search />
        </button>
      </form>
    </div>
  )
}
