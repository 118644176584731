import React from 'react'
import PropTypes from 'prop-types'
import { Text } from '@sitecore-jss/sitecore-jss-react'

import { icons } from '../../../assets/icons'

export const Reseller = ({
  name,
  city,
  zip,
  streetAddress,
  phoneNumber,
  openHours,
  id,
  resellerId,
  selectedResellerId,
  handleSelectReseller,
  translations
}) => {
  const resellerIsActive = resellerId === selectedResellerId

  return (
    <div
      id={id}
      onClick={e => handleSelectReseller(e)}
      className={`reseller-item ${resellerIsActive && 'reseller-item--active'}`}
    >
      {resellerIsActive && (
        <div>
          <icons.CheckMarkBorder />
        </div>
      )}
      <div>
        <div className="reseller-item__title"> {city}</div>
        <div className="reseller-item__description">
          {name}, {city}
        </div>
        <div className="reseller-item__description">
          {zip} {city}, {streetAddress}
        </div>
        <div className="reseller-item__description">
          {' '}
          <Text field={translations.PhoneNumber} />: {phoneNumber}
        </div>
        {/* <div className="reseller-item__description">
          <Text field={translations.OpenHours} />: {openHours}
        </div> */}
      </div>
    </div>
  )
}

Reseller.propTypes = {
  name: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  zip: PropTypes.string.isRequired,
  streetAddress: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  openHours: PropTypes.string.isRequired
}
