import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import { NavigationLink } from './NavigationLink'
import { Logo } from './Logo'
import { icons } from '../../assets/icons'
import { useWindowSize } from '../../../../Foundation/hooks/useWindowSize'

const Navigation = ({ fields: { navigation = [], datasourceItems = [] } }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const { width } = useWindowSize()

  const location = useLocation()

  useEffect(() => {
    setIsMenuOpen(false)
  }, [location.pathname])

  const handleToggleMenu = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  const handleCloseMenu = () => {
    setIsMenuOpen(false)
  }

  const renderNavLinks = (navigation || [])
    .filter(navigationItem => !navigationItem.hideInNavigation)
    .map(navigationItem => (
      <NavigationLink
        key={navigationItem.title}
        label={navigationItem.title}
        externalTarget={navigationItem.externalTarget}
        url={navigationItem.url}
      />
    ))

  const renderLogo = datasourceItems?.Logo

  return width < 1024 ? (
    <div className="navigation">
      <div
        role="button"
        tabIndex="0"
        onClick={handleToggleMenu}
        onKeyPress={handleToggleMenu}
        className="navigation__hamburger"
      >
        <icons.Hamburger />
      </div>
      <Logo logo={renderLogo} />
      {isMenuOpen ? (
        <div className="menu">
          <div className="menu__items"> {renderNavLinks}</div>
          <div
            className="menu__cross"
            role="button"
            tabIndex="0"
            onClick={handleCloseMenu}
            onKeyPress={handleCloseMenu}
          >
            <icons.Cross />
          </div>
        </div>
      ) : null}
    </div>
  ) : (
    <div className="navigation">
      <div className="navigation__wrapper">
        <Logo logo={renderLogo} />
        {renderNavLinks}
      </div>
    </div>
  )
}

Navigation.propTypes = {
  fields: PropTypes.shape({
    navigation: PropTypes.arrayOf(
      PropTypes.shape({
        hideInNavigation: PropTypes.bool.isRequired,
        title: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired
      })
    ).isRequired,
    navigationItems: PropTypes.shape({
      Logo: PropTypes.shape({
        value: PropTypes.shape({
          src: PropTypes.string.isRequired,
          alt: PropTypes.string.isRequired
        }).isRequired
      }).isRequired
    }).isRequired
  }).isRequired
}

export default Navigation
