import React from 'react'

export const Search = () => {
  return (
    <svg
      width="18px"
      height="20px"
      viewBox="0 0 18 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Icon/search_icon</title>
      <g
        id="Startpage"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Desktop-checkout-1.1"
          transform="translate(-678.000000, -4039.000000)"
          stroke="#2D96CD"
        >
          <g id="leverans" transform="translate(250.000000, 3956.000000)">
            <g id="Group" transform="translate(428.000000, 83.000000)">
              <circle id="Oval" cx="7.5" cy="7.5" r="7" />
              <line
                x1="12.5"
                y1="13.5"
                x2="16"
                y2="18"
                id="Line-2"
                strokeWidth="2"
                strokeLinecap="square"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
