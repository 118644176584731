import React from 'react'

export const CheckMarkBorder = () => (
  <svg
    className="checkmark"
    viewBox="0 0 64 64"
    aria-labelledby="title"
    aria-describedby="desc"
    role="img"
  >
    <circle
      data-name="SuccessCircle"
      cx={32}
      cy={32}
      r={30}
      transform="rotate(-45 32 32)"
      fill="none"
      stroke="#008200"
      strokeMiterlimit={10}
      strokeWidth={2}
      strokeLinejoin="round"
      strokeLinecap="round"
      className="checkmark__circle"
    />
    <path
      data-name="SuccessPath"
      fill="none"
      stroke="#008200"
      strokeMiterlimit={10}
      strokeWidth={2}
      d="M20.998 32.015l8.992 8.992 16.011-16.011"
      strokeLinejoin="round"
      strokeLinecap="round"
      className="checkmark__check"
    />
  </svg>
)
