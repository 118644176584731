import { Form } from '@sitecore-jss/sitecore-jss-react-forms'
import React from 'react'
import { withRouter } from 'react-router-dom'
import { sitecoreApiHost, sitecoreApiKey } from '../../../../temp/config'
import { FieldTypes } from '@sitecore-jss/sitecore-jss-react-forms'
import { createDefaultFieldFactory } from '@sitecore-jss/sitecore-jss-react-forms'

const defaultFieldFactory = createDefaultFieldFactory()

defaultFieldFactory.setComponent(FieldTypes.Section, props => {
  const items = props.field.fields.map((item, index) => (
    <div key={index} className="form-group col-6">
      <label className="checkbox-inline">{item.model.title}</label>
      <input className="form-control" />
    </div>
  ))
  return <div className="row">{items}</div>
})

defaultFieldFactory.setComponent(FieldTypes.Button, props => (
  <div className="form-group">
    <button className="btn btn-primary">{props.field.model.title}</button>
  </div>
))

defaultFieldFactory.setComponent(FieldTypes.SingleLineText, props => (
  <div className="form-group">
    <label className="checkbox-inline">{props.field.model.title}</label>
    <input className="form-control" />
  </div>
))

defaultFieldFactory.setComponent(FieldTypes.Email, props => (
  <div className="form-group">
    <label className="checkbox-inline">{props.field.model.title}</label>
    <input className="form-control" type="email" />
  </div>
))

defaultFieldFactory.setComponent(FieldTypes.MultipleLineText, props => (
  <div className="form-group">
    <label className="checkbox-inline">{props.field.model.title}</label>
    <textarea className="form-control" rows="6" />
  </div>
))

const JssForm = ({ fields, history }) => (
  <Form
    form={fields}
    sitecoreApiHost={sitecoreApiHost}
    sitecoreApiKey={sitecoreApiKey}
    onRedirect={url => history.push(url)}
    fieldFactory={defaultFieldFactory}
  ></Form>
)

export default withRouter(JssForm)
