import React, { forwardRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import GoogleMapReact from 'google-map-react'
import MarkerClusterer from '@google/markerclusterer'
import { resellersActions, googleMapActions } from '../../../actions'
import { getAllLocations, findMarker } from '../../../helpers'
import {
  getGoogleMapKey,
  getGoogleMapCenter,
  getGoogleMapZoom,
  getResellersIds,
  getResellersByIds
} from '../../../selectors'

export const GoogleMap = forwardRef((_, ref) => {
  const dispatch = useDispatch()
  const key = useSelector(getGoogleMapKey)
  const center = useSelector(getGoogleMapCenter)
  const zoom = useSelector(getGoogleMapZoom)
  const resellersIds = useSelector(getResellersIds)
  const resellersById = useSelector(getResellersByIds)

  const handleMarkerClick = marker => () => {
    const reseller = findMarker(marker, resellersIds, resellersById)

    dispatch(resellersActions.setCurrentResellers([reseller]))
  }

  const createMarkers = () => {
    const locations = getAllLocations(resellersIds, resellersById)

    return locations.map(location => {
      const marker = new ref.googleRef.current.Marker({ position: location })

      marker.addListener('click', handleMarkerClick(marker))

      return marker
    })
  }

  const createCluster = (map, markers) =>
    new MarkerClusterer(map, markers, {
      imagePath:
        'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'
    })

  const setGoogleMapRef = ({ map, maps }) => {
    ref.googleRef.current = maps
    ref.googleMapRef.current = map

    const markers = createMarkers()

    createCluster(map, markers)
  }

  const handleMapChange = ({ center: centerMap, zoom: zoomMap }) => {
    dispatch(googleMapActions.setCenterMap(centerMap))
    dispatch(googleMapActions.setZoomMap(zoomMap))
  }

  return key ? (
    <div className="resellers__map">
      <GoogleMapReact
        bootstrapURLKeys={{ key }}
        center={center}
        zoom={zoom}
        onChange={handleMapChange}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={setGoogleMapRef}
      ></GoogleMapReact>
    </div>
  ) : null
})
