import React, { useState } from 'react'
import { Text } from '@sitecore-jss/sitecore-jss-react'

import { icons } from '../../../../assets/icons'
import { InputRangeSlider } from '../../components'
import { Button } from '../../../../../../Foundation/components/Button'
import { DROPDOWN_DATA } from '../../../../configs'
import { AccordionItem } from './AccordionItem'
import { Chips } from '../Desktop/Chips'

export const FiltersMobile = ({ translations }) => {
  const [expanded, setExpanded] = useState(false)

  const accordions = DROPDOWN_DATA.map(dropdownItem => (
    <AccordionItem
      key={dropdownItem.type}
      {...dropdownItem}
      translations={translations}
    />
  ))

  const handleExpand = () => {
    setExpanded(!expanded)
  }

  return (
    <div>
      <Text
        field={translations.CurrentCampaignsTitle}
        tag="div"
        className="filter-mobile__title"
      />
      <div className="filter-mobile">
        <div
          className="filter-mobile__wrapper"
          role="button"
          tabIndex="0"
          onKeyPress={handleExpand}
          onClick={handleExpand}
        >
          <div className="filter-mobile__text__wrapper">
            <div className="filter-mobile__icon">
              <icons.Filter />
            </div>
            <Text
              field={translations.FilterCarsMobile}
              tag="div"
              className="filter-mobile__text"
            />
          </div>
          {expanded ? <icons.ArrowUp /> : <icons.ArrowDown />}
        </div>
        {expanded ? (
          <div className="filter-mobile-items">
            {accordions}
            <InputRangeSlider translations={translations} />
            {/* <div className="filter-mobile__button">
              <Button text="Stäng" border onClick={handleExpand} />
            </div> */}
          </div>
        ) : (
          <Chips />
        )}
      </div>
    </div>
  )
}
