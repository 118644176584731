import React from 'react'
import { useSelector } from 'react-redux'

import { CarSummaryItem } from '../CarSummaryItem'

import {
  getOptionsByIdData,
  getColorsById,
  getCarBasePrice
} from '../../../selectors'

export const ActiveOptions = ({ configurations, colorLabel, carLabel }) => {
  const color = useSelector(getColorsById(configurations.color))
  const optionsByIdData = useSelector(getOptionsByIdData)
  const carBasePrice = useSelector(getCarBasePrice)

  const optionsJSX = configurations.options.map(optionsId => {
    const { variantId, displayName, prices, isOneTimeCost } = optionsByIdData[
      optionsId
    ]

    const price = prices.find(
      price => price.duration === configurations.contractLengthSize
    )?.price

    return (
      !isOneTimeCost && (
        <CarSummaryItem key={variantId} title={displayName} price={price} />
      )
    )
  })

  const colorPrice = color?.price
  const colorTitle = `${colorLabel} ${color?.description}`

  return (
    <>
      <CarSummaryItem title={carLabel} price={carBasePrice} />
      <CarSummaryItem title={colorTitle} price={colorPrice} />
      {optionsJSX}
    </>
  )
}
