import React from 'react'
import { loader as gqlLoader } from 'graphql.macro'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import SitecoreContextFactory from '../../../../Foundation/Context/SitecoreContextFactory'
import GraphQLData from '../../../../Foundation/GraphQL/GraphQLData'

const GET_LANGUAGES = gqlLoader('./query.graphql')

class LanguageSwitcher extends React.Component {
  onChangeLanguage(newLanguage) {
    const context = SitecoreContextFactory.getSitecoreContext()
    SitecoreContextFactory.setSitecoreContext({
      ...context,
      language: newLanguage,
      refresh: true
    })
  }

  render() {
    if (!this.props.languageQuery || !this.props.languageQuery.item) return null

    const availableLanguages = this.props.languageQuery.item.versions.map(
      version => {
        return version.language.name
      }
    )

    const languages = availableLanguages
      .filter(language => language !== this.props.sitecoreContext.language)
      .map((language, index) => {
        return (
          <button
            key={index}
            onClick={() => this.onChangeLanguage(language)}
            className="dropdown-item text-white"
          >
            {language.split('-')[0].toUpperCase()}
          </button>
        )
      })
    return (
      <ul className="navbar-nav">
        <li className="nav-item dropdown">
          <p
            className="nav-link m-0 dropdown-toggle text-light"
            id="navbarDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {this.props &&
              this.props.sitecoreContext.language.split('-')[0].toUpperCase()}
          </p>
          <div
            className="dropdown-menu bg-secondary"
            aria-labelledby="navbarDropdown"
          >
            {languages}
          </div>
        </li>
      </ul>
    )
  }
}

export default withSitecoreContext()(({ sitecoreContext }) => {
  const WrappedComponent = GraphQLData(GET_LANGUAGES, {
    name: 'languageQuery',
    options: {
      variables: {
        applicationPath: `/sitecore/content/${sitecoreContext.site.name}`
      }
    }
  })(LanguageSwitcher)
  return <WrappedComponent />
})
