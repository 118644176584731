import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { RichText } from '@sitecore-jss/sitecore-jss-react'
import { t } from 'i18next'

import {
  getOptionsById,
  getSelectedOptions,
  getSelectedReadableOptions,
  getConfigurations
} from '../../../../selectors'
import { AccordionSummary } from '../AccordionSummary'
import { AccordionDetails } from '../AccordionDetails'
import { UsefulCheckbox } from '../../../../../../Foundation/components/Checkbox'
import { icons } from '../../../../assets/icons'
import { carOptionsActions } from '../../../../actions'

export const AccordionItem = ({ id }) => {
  const dispatch = useDispatch()
  const {
    variantId,
    displayName,
    prices,
    description,
    image,
    productId,
    longDelivery,
    isOneTimeCost,
    oneTimeCost,
    isMandatory
  } = useSelector(getOptionsById(id))
  const selectedOptions = useSelector(getSelectedOptions)
  const selectedReadableOptions = useSelector(getSelectedReadableOptions)
  const configurations = useSelector(getConfigurations)

  const [expanded, setExpanded] = useState(false)

  const isSelected = selectedOptions.includes(variantId)
  const descriptionObj = { value: description }

  const handleClick = () => {
    setExpanded(!expanded)
  }

  const handleCheckboxClick = event => {
    event.preventDefault()

    if (!isMandatory) {
      const values = isSelected
        ? selectedOptions.filter(selectedOption => selectedOption !== variantId)
        : [...selectedOptions, variantId]

      const optionsReadableIds = isSelected
        ? selectedReadableOptions.filter(
            selectedOption => selectedOption !== productId
          )
        : [...selectedReadableOptions, productId]

      dispatch(
        carOptionsActions.setCarSelectedOption({
          options: values,
          optionsReadableIds: optionsReadableIds
        })
      )
    }
  }

  const ArrowIcon = expanded ? icons.ArrowUp : icons.ArrowDown

  const foundMatchingPrice = prices.find(
    price => price.duration === configurations.contractLengthSize
  )?.price

  return (
    <div className="car-accordion">
      <AccordionSummary
        expanded={expanded}
        icon={<ArrowIcon color="2d96cd" />}
        onClick={handleClick}
      >
        <div
          className={`car-accordion__inner ${isMandatory &&
            'car-accordion__inner--isMandatory'}`}
          onClick={() => handleCheckboxClick(event)}
        >
          <UsefulCheckbox
            checked={isSelected}
            isMandatory={isMandatory}
            onChange={() => {}}
          />
          <div className="car-accordion__title">
            {displayName}{' '}
            <span className="car-accordion__title--bold">
              {isOneTimeCost ? (
                <>
                  {' '}
                  {oneTimeCost} {t('currency')}{' '}
                </>
              ) : (
                <>
                  {' '}
                  +{foundMatchingPrice} {t('currency_per_month')}
                </>
              )}
            </span>
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails isPadding={!!image} expanded={expanded}>
        {image ? <div className="car-accordion__image" /> : null}
        <div className="car-accordion__content">
          <RichText field={descriptionObj} />
        </div>
      </AccordionDetails>
    </div>
  )
}

AccordionItem.propTypes = {
  id: PropTypes.string.isRequired
}
