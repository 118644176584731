import React from 'react'
import { Button } from '../../../../../../Foundation/components/Button'
import { getAlignClass } from '../../../../helpers'

export const LinkButton = ({ align, title }) => {
  const { href, text } = title.value

  const alignClass = getAlignClass(align)

  return (
    <div className={`image-text__button ${alignClass}`}>
      <Button text={text} url={href} border />
    </div>
  )
}
