import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { UsefulDropdown } from '../../../../../../../Foundation/components/Dropdown'
import { carListActions } from '../../../../../actions'
import {
  getFilterByType,
  getFiltersSelectedByType
} from '../../../../../selectors'

export const DropdownItem = ({ type, translations }) => {
  const dispatch = useDispatch()
  const filter = useSelector(getFilterByType(type))
  const selected = useSelector(getFiltersSelectedByType(type))

  const createOption = option => ({
    label: option,
    value: option
  })

  const filterOptions = filter.map(createOption)
  const selectedOptions = selected.map(createOption)

  const handleChange = itemProps => {
    const updatedSelected = itemProps.map(item => item.label)

    dispatch(carListActions.updateCarsFilter(type, updatedSelected))
  }

  return (
    <UsefulDropdown
      label={translations[type]?.value}
      options={filterOptions}
      selected={selectedOptions}
      onChange={handleChange}
      selectAllLabel={translations.SelectAll.value}
    />
  )
}

DropdownItem.propTypes = {
  type: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
}
