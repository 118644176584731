import React from 'react'

import { useWindowSize } from '../../../../../Foundation/hooks/useWindowSize'
import { FiltersDesktop } from './Desktop'
import { FiltersMobile } from './Mobile'

export const Filters = ({ translations }) => {
  const { width } = useWindowSize()

  return width < 768 ? (
    <FiltersMobile translations={translations} />
  ) : (
    <FiltersDesktop translations={translations} />
  )
}
