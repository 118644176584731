import { resellersTypes } from '../types'

export const resellersActions = Object.freeze({
  setResellers: fields => dispatch => {
    return dispatch({
      type: resellersTypes.SET_RESELLERS,
      payload: fields
    })
  },
  searchResellers: text => dispatch => {
    return dispatch({
      type: resellersTypes.SEARCH_RESELLERS,
      payload: text
    })
  },
  setCurrentResellers: resellers => dispatch => {
    return dispatch({
      type: resellersTypes.SET_CURRENT_RESELLERS,
      payload: resellers
    })
  },
  selectReseller: resellerId => dispatch => {
    return dispatch({
      type: resellersTypes.SELECT_RESELLER,
      payload: resellerId
    })
  },
  resetSelectedReseller: isError => dispatch => {
    return dispatch({
      type: resellersTypes.RESET_SELECTED_RESELLER,
      payload: isError
    })
  }
})
