import React from 'react'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import { loader as gqlLoader } from 'graphql.macro'
import RenderCookieAcceptance from './CookieAcceptance.js'
import { compose } from 'react-apollo'
import GraphQLData from '../../../../Foundation/GraphQL/GraphQLData'

const GET_COOKIE_QUERY = gqlLoader('./query.graphql')

const Cookie = withSitecoreContext()(({ sitecoreContext }) => {
  const WrappedComponent = compose(
    GraphQLData(GET_COOKIE_QUERY, {
      name: 'cookieQuery',
      options: {
        variables: {
          path: `/sitecore/content/${sitecoreContext.site.name}/Content/Global/Cookie`,
          lang: sitecoreContext.language
        }
      }
    })
  )(RenderCookieAcceptance)
  return <WrappedComponent />
})

export default Cookie
