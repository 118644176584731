import { Hamburger } from './Hamburger'
import { Cross } from './Cross'
import { SmallCross } from './Small-Cross'
import { ArrowRight } from './ArrowRight'
import { ArrowDown } from './ArrowDown'
import { ArrowUp } from './ArrowUp'
import { CheckMark } from './CheckMark'
import { CheckMarkBorder } from './CheckMarkBorder'
import { Filter } from './Filter'
import { SortArrowDown } from './SortArrowDown'
import { SortArrowUp } from './SortArrowUp'
import { Search } from './Search'
import { Cognito } from './Cognito'

export const icons = {
  Hamburger,
  Cross,
  ArrowRight,
  ArrowDown,
  ArrowUp,
  CheckMark,
  CheckMarkBorder,
  Filter,
  SmallCross,
  SortArrowDown,
  SortArrowUp,
  Search,
  Cognito
}
