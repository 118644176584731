import React from 'react'
import { RichText, Text } from '@sitecore-jss/sitecore-jss-react'

export const FooterColumn = ({ title, text, links }) => {
  return (
    <div className="footer__column">
      <Text className="footer__title" field={title} tag="div" />
      {text.value ? (
        <RichText className="footer__text" field={text} tag="div" />
      ) : null}
      {links.length > 0 && <div className="footer__links">{links}</div>}
    </div>
  )
}
