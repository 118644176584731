import { googleMapTypes } from '../types'

export const googleMapActions = Object.freeze({
  setGoogleKey: key => dispatch => {
    return dispatch({
      type: googleMapTypes.SET_GOOGLE_KEY,
      payload: key
    })
  },
  setCenterMap: center => dispatch => {
    return dispatch({
      type: googleMapTypes.SET_CENTER_MAP,
      payload: center
    })
  },
  setZoomMap: zoom => dispatch => {
    return dispatch({
      type: googleMapTypes.SET_ZOOM_MAP,
      payload: zoom
    })
  }
})
