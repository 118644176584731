import React from 'react'
import { NavLink } from 'react-router-dom'
import { Text, Image, Link } from '@sitecore-jss/sitecore-jss-react'

const Card = ({ fields, params }) => {
  return params && params.ImageCard ? (
    <div className="card bg-dark border-0">
      <NavLink to={fields.Link.value.href}>
        <Image field={fields.Image} className="card-img img-fluid" />
        <div className="card-img-overlay text-white d-flex flex-column align-items-center justify-content-end">
          <Text
            tag="h3"
            field={fields.Title}
            className={`card-title text-center`}
          />
          <Text
            tag="h5"
            field={fields.Text}
            className={`card-text text-center`}
          />
        </div>
      </NavLink>
    </div>
  ) : (
    <div className={`card`}>
      <Image field={fields.Image} className="card-img img-fluid" />
      <div className={`card-body`}>
        <Text tag="h2" field={fields.Title} className={`card-title`} />
        <Text tag="p" field={fields.Text} className={`card-text`} />
        <Link
          field={fields.Link}
          className="card-text btn btn-primary rounded-0 text-light mt-2"
        />
      </div>
    </div>
  )
}

export default Card
