import { carOptionsTypes } from '../types'

import { getTotalPrice, getAllowedMileages, getCarBasePrice } from '../helpers'

const initialState = {
  data: {
    colors: {
      byId: {},
      allIds: []
    },
    contractLengths: {
      byId: {},
      allIds: []
    },
    contractMileages: {
      byId: {},
      allIds: []
    },
    options: {
      byId: {},
      allIds: []
    },

    price: 0
  },
  configurations: {
    color: '',
    contractLength: '',
    contractMileage: '',
    options: [],
    optionsReadableIds: [],
    carIsLongDelivery: false,
    colorIsLongDelivery: false,
    optionsIsLongDelivery: false,
    isOverrideDelivery: false,
    isLongDelivery: false
  },

  totalPrice: 0,
  userMadeOrder: ''
}

export default (state = initialState, action) => {
  switch (action.type) {
    case carOptionsTypes.SET_USER_MADE_ORDER:
      return {
        ...state,
        userMadeOrder: action.payload
      }
    case carOptionsTypes.SET_CAR_OPTIONS:
      const {
        colors,
        contractLengths,
        contractMileages,
        options,
        ...rest
      } = action.payload

      const normalizedColors = normalizeData(colors, 'variantId')
      const normalizedContractLengths = normalizeData(
        contractLengths,
        'variantId'
      )
      const normalizedContractMileages = normalizeData(
        contractMileages,
        'variantId'
      )
      const normalizedOptions = normalizeData(options, 'variantId')

      return {
        ...state,
        data: {
          ...state.data,
          ...rest,
          colors: normalizedColors,
          contractLengths: normalizedContractLengths,
          contractMileages: normalizedContractMileages,
          options: normalizedOptions
        }
      }
    case carOptionsTypes.SET_CAR_SELECTED_OPTION:
      const configurations = {
        ...state.configurations,
        ...action.payload
      }

      // Contract length and period logic.
      if (action.payload.contractLength) {
        // Set contract months length. There is 18/36 months etc...
        configurations.contractLengthSize =
          state.data.contractLengths.byId[action.payload.contractLength].size

        // Scenario: If 36 months is selected, only some mileages are allowed for example 3000/4500/6000 miles.
        getAllowedMileages(state, configurations).forEach((item, i) => {
          const contractMileagesList = state.data.contractMileages.byId

          if (i === 0) {
            Object.keys(contractMileagesList)
              .filter(key => item.mileage === contractMileagesList[key].size)
              .forEach(key => {
                configurations.contractMileage =
                  contractMileagesList[key].variantId
                configurations.contractMileageSize =
                  contractMileagesList[key].size
              })
          }
        })
      }
      ///////////////////////////////////

      // Get configurations
      const getContractLength =
        state.data.contractLengths.byId[configurations.contractLength]
      const getContractMileage =
        state.data.contractMileages.byId[configurations.contractMileage]
      const getColor = state.data.colors.byId[configurations.color]
      const getOptions = state.data.options.byId
      //

      // Get prices logic
      // Each period/mileage has it's own price. This is the list.
      const carBasePricesList = state.data.prices
      // Each options has it's own price.
      const getOptionsPrice = configurations.options.reduce((acc, curr) => {
        const { prices } = getOptions[curr]

        const foundMatchingPrice = prices.find(
          price => price.duration === configurations.contractLengthSize
        )?.price

        return (acc += foundMatchingPrice)
      }, 0)

      const totalPrice = getTotalPrice(
        carBasePricesList,
        getOptionsPrice,
        getContractLength,
        getContractMileage,
        getColor
      )

      const carBasePrice = getCarBasePrice(
        carBasePricesList,
        getContractLength,
        getContractMileage
      )?.price

      configurations.carBasePrice = carBasePrice
      configurations.optionsPrice = getOptionsPrice

      // Terminology
      // Options: Extra equipments .
      // Color: Color of the car.
      // Car base: A naked car without any equipments and standard color.

      // Priority level
      // 1. overrideDelivery
      // 2. carIsLongDelivery
      // 2. colorIsLongDelivery
      // 3. optionsIsLongDelivery

      // We have 2 delivery times long and short. In our redux state you can see these properties.
      // bool overrideDelivery: This will always override long delivery and give short delivery.
      // bool carIsLongDelivery: This will always be long delivery .
      // bool carIsLongDelivery: If true this cannot be changed when selecting options.
      // bool optionsIsLongDelivery: If false it will be short delivery and if true it will be long delivery.

      // Where can these properties be found?
      // overrideDelivery can only be found on colors and options.
      // optionsIsLongDelivery can be found on colors and options.
      // carIsLongDelivery can be found on car.

      const optionsIsLongDelivery = configurations.options.some(
        option => !!getOptions[option].longDelivery
      )

      const optionsIsOverrideDelivery = configurations.options.some(
        option => !!getOptions[option].overrideDelivery
      )

      configurations.isLongDelivery = true

      if (!configurations.carIsLongDelivery) {
        configurations.isLongDelivery =
          getColor.longDelivery || optionsIsLongDelivery
      }

      if (!!configurations.carIsLongDelivery) {
        configurations.isLongDelivery = true
      }

      if (!!getColor.overrideDelivery || !!optionsIsOverrideDelivery) {
        configurations.isLongDelivery = false
      }

      // end of delivery time logic

      return {
        ...state,
        configurations,
        totalPrice
      }
    default:
      return state
  }
}

const normalizeData = (data, nameId) => {
  const normalizedData = {
    byId: {},
    allIds: []
  }

  data.forEach(item => {
    const itemId = item[nameId]

    normalizedData.allIds.push(itemId)

    normalizedData.byId[itemId] = item
  })

  return normalizedData
}
