import React, { Fragment } from 'react'
import { NavLink } from 'react-router-dom'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import { loader as gqlLoader } from 'graphql.macro'
import GraphQLData from '../../../../Foundation/GraphQL/GraphQLData'

const GET_BLOG_ROUTES_QUERY = gqlLoader('./query.graphql')

const BlogRoutes = props => {
  const size = {
    height: '15rem'
  }
  const image = {
    width: '100%',
    height: '100%',
    position: 'relative',
    objectFit: 'cover'
  }

  let NewsBlock = ''
  const { item } = props.blogQuery

  if (item !== undefined) {
    const filteredRoutes = item.children
      .filter(route => {
        return route.template.name === 'Blog Route'
      })
      .sort((a, b) => {
        return (
          new Date(b.dateCreated.jss.value) - new Date(a.dateCreated.jss.value)
        )
      })

    NewsBlock = filteredRoutes.map((route, index) => {
      return (
        <div
          key={index}
          className={`card border-0 col-sm-6 col-lg-4 col-xl-3 p-1`}
          style={size}
        >
          <img
            src={route.blogImage.jss.value.src}
            alt=""
            className="img-fluid"
            style={image}
          />
          <NavLink to={`${props.sitecoreContext.route.name}/${route.name}`}>
            <div
              className={`card-img-overlay d-flex align-items-center justify-content-center`}
            >
              <h4 className={`card-title text-center text-white mt-5`}>
                {route.blogTitle.jss.value}
              </h4>
            </div>
            <div
              className={`card-img-overlay d-flex align-items-end justify-content-center`}
            >
              <p className={`card-text text-center text-white mb-1`}>
                {route.blogText.jss.value}
              </p>
            </div>
          </NavLink>
        </div>
      )
    })
  }
  return (
    <Fragment>
      <div className={`row`}>{NewsBlock}</div>
    </Fragment>
  )
}

export default withSitecoreContext()(({ sitecoreContext }) => {
  const WrappedComponent = GraphQLData(GET_BLOG_ROUTES_QUERY, {
    name: 'blogQuery',
    options: {
      variables: { settingsId: `${sitecoreContext.itemId}` }
    }
  })(BlogRoutes)
  return <WrappedComponent />
})
