import React from 'react'
import CookieConsent from 'react-cookie-consent'

const RenderCookieAcceptance = props => {
  if (!props.cookieQuery.item) {
    return null
  }

  const richText = props.cookieQuery.item.children[0].richText.jss.value
  const buttonText = props.cookieQuery.item.children[0].buttonText.value

  return (
    <div>
      <CookieConsent
        location="bottom"
        cookieName="user-accepted-cookie"
        expires={150}
        disableButtonStyles={true}
        buttonClasses="cookie__button"
        buttonText={buttonText}
        containerClasses="cookie"
      >
        <span dangerouslySetInnerHTML={{ __html: richText }}></span>
      </CookieConsent>
    </div>
  )
}

export default RenderCookieAcceptance
