import React from 'react'
import { Image } from '@sitecore-jss/sitecore-jss-react'
import image1 from '../../../../assets/images/200x300.png'

const ImageObj = {
  value: {
    src: image1,
    alt: ''
  }
}

export const Photo = ({ fullWidth, image }) => {
  const fullWidthClass = fullWidth ? 'fullWidth' : ''

  return (
    <Image className={`image-text__image ${fullWidthClass}`} field={image} />
  )
}
