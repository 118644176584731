import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { Filters } from './Filters'
import { carListActions } from '../../actions'
import { Cars } from './Cars'
import { SortPrice } from './SortPrice'

const CarList = ({ fields }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(carListActions.setCarList(fields))
  }, [])

  const translations = fields.datasourceItems

  return (
    <div className="container-spacing--lg">
      <Filters translations={translations} />
      <SortPrice translations={translations} />
      <Cars translations={translations} />
    </div>
  )
}

CarList.propTypes = {
  fields: PropTypes.shape({
    brands: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    carTypes: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    fuels: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    gearBoxes: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    prices: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
    cars: PropTypes.arrayOf(
      PropTypes.shape({
        brand: PropTypes.string.isRequired,
        campaignText: PropTypes.string.isRequired,
        carType: PropTypes.string.isRequired,
        carUrl: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        fuel: PropTypes.string.isRequired,
        gearBox: PropTypes.string.isRequired,
        image: PropTypes.oneOf([null]).isRequired,
        isEl: PropTypes.bool.isRequired,
        isNew: PropTypes.bool.isRequired,
        name: PropTypes.string.isRequired,
        ordPrice: PropTypes.number.isRequired,
        price: PropTypes.number.isRequired,
        productId: PropTypes.string.isRequired,
        sticker: PropTypes.string.isRequired
      })
    ).isRequired
  }).isRequired
}

export default CarList
