import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Text } from '@sitecore-jss/sitecore-jss-react'
import PropTypes from 'prop-types'
import { AccordionSummary } from '../AccordionSummary'
import { icons } from '../../../../../assets/icons'
import { AccordionDetails } from '../AccordionDetails'
import { UsefulCheckbox } from '../../../../../../../Foundation/components/Checkbox'
import { carListActions } from '../../../../../actions'
import { updateSelected, setCheck } from '../../../../../helpers'
import { LABEL_ALL } from '../../../../../configs'
import {
  getFilterByType,
  getFiltersSelectedByType
} from '../../../../../selectors'

const checkboxlabelStyle = {
  marginLeft: 8,
  fontSize: 14,
  color: '#6d6d6d',
  letterSpacing: 0.78
}

export const AccordionItem = ({ type, label, translations }) => {
  const dispatch = useDispatch()
  const filter = useSelector(getFilterByType(type))
  const selected = useSelector(getFiltersSelectedByType(type))
  const [expanded, setExpanded] = useState(false)

  const selectAllLabel = translations.SelectAll.value
  const filterWithAll = [selectAllLabel, ...filter]

  const handleChange = (labelText, checked) => {
    const copySelected = [...selected]

    const updatedSelected = updateSelected(
      labelText,
      checked,
      copySelected,
      filter
    )

    dispatch(carListActions.updateCarsFilter(type, updatedSelected))
  }

  const checkboxes = filterWithAll.map(filterItem => {
    const checked = setCheck(filterItem, filter, selected)

    return (
      <UsefulCheckbox
        key={filterItem}
        onChange={handleChange}
        label={filterItem}
        checked={checked}
        labelStyle={checkboxlabelStyle}
      />
    )
  })

  const handleClick = () => {
    setExpanded(!expanded)
  }

  const ArrowIcon = expanded ? icons.ArrowUp : icons.ArrowDown

  return (
    <div className="filter-mobile-accordion">
      <AccordionSummary
        expanded={expanded}
        icon={<ArrowIcon color="00095b" />}
        onClick={handleClick}
      >
        <Text
          className="filter-mobile-accordion__summary__title"
          field={translations[type]}
          tag="div"
        />
      </AccordionSummary>
      <AccordionDetails expanded={expanded}>{checkboxes}</AccordionDetails>
    </div>
  )
}

AccordionItem.propTypes = {
  type: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
}
