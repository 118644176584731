export const getColorStyle = (color, colorStyle) => {
  if (colorStyle === 'Special' || colorStyle === 'Metallic') {
    return {
      backgroundImage: `linear-gradient(to bottom, rgba(254, 252, 252, 0.93), rgba(255, 255, 255, 0) 50%), linear-gradient(to bottom, ${color}, ${color})`
    }
  }

  return { backgroundColor: color }
}

export const getTotalPrice = (
  carBasePricesList,
  optionsPrice,
  contractLength,
  contractMileage,
  color
) => {
  const foundMatchingCar = getCarBasePrice(
    carBasePricesList,
    contractLength,
    contractMileage
  )

  let totalPrice = foundMatchingCar?.price + optionsPrice

  if (color) {
    const foundColorPrice = color.prices.find(
      item => item.duration === contractLength.size
    )?.price

    totalPrice += foundColorPrice
  }

  return totalPrice
}

export const getAllowedMileages = (state, configurations) => {
  const selectedContract = state.data.colors.byId

  const filteredMileageList = []

  Object.keys(selectedContract).forEach(function(key) {
    selectedContract[key].prices.forEach(item => {
      if (item.duration === configurations.contractLengthSize) {
        filteredMileageList.push(item)
      }
    })
  })

  // array has duplicates, we want unique list
  const uniqueMileagesList = filteredMileageList
    .filter((v, i, a) => a.findIndex(t => t.mileage === v.mileage) === i)
    .sort((a, b) => a.mileage - b.mileage)

  return uniqueMileagesList
}

export const getCarBasePrice = (
  carDefaultPricesList,
  contractLength,
  contractMileage
) => {
  const carBasePrice = carDefaultPricesList.find(
    item =>
      item.duration === contractLength.size &&
      item.mileage === contractMileage.size
  )

  return carBasePrice
}
