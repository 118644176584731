import React from 'react'
import { useSelector } from 'react-redux'
import { t } from 'i18next'
import NumberFormat from 'react-number-format'
import { Text } from '@sitecore-jss/sitecore-jss-react'

import {
  getOptionsByIdData,
  getColorsById,
  getCarBasePrice,
  getTotalPrice
} from '../../../selectors'

export const TotalCost = ({
  configurations,
  totalCostLabel,
  isInsuranceOption,
  insuranceCompany
}) => {
  const totalPrice = useSelector(getTotalPrice)
  const optionsByIdData = useSelector(getOptionsByIdData)

  const oneTimeCostJSX = configurations.options.map(optionsId => {
    const { displayName, prices, isOneTimeCost, oneTimeCost } = optionsByIdData[
      optionsId
    ]

    return (
      isOneTimeCost && (
        <div className="car-total__item">
          <div className="car-total__name car-total__name--bold">
            {displayName}
          </div>
          <div className="car-total__price car-total__price--bold">
            {oneTimeCost} {t('currency')}
          </div>
        </div>
      )
    )
  })

  return (
    <div className="car-total__footer">
      <div className="car-total__item car-total__monthly-cost">
        <Text
          className="car-total__name car-total__name--bold"
          tag="div"
          field={totalCostLabel}
        />
        <div className="car-total__price car-total__price--bold">
          <NumberFormat
            value={totalPrice}
            thousandSeparator={' '}
            displayType={'text'}
          />{' '}
          {t('currency_per_month')}
        </div>
      </div>
      {oneTimeCostJSX}
    </div>
  )
}
