import React from 'react'
import { Text } from '@sitecore-jss/sitecore-jss-react'

const Header = ({ fields, params }) => {
  const textCenter = params && params.CenterText ? 'text-center' : ''
  const marginTop = params && params.MarginTop ? 'mt-5' : ''
  const marginBottom = params && params.MarginBottom ? 'mb-3' : ''

  return (
    <div className={`${textCenter} ${marginTop} ${marginBottom}`}>
      <Text tag="h1" field={fields.Title} />
      <Text tag="h5" className="text-muted" field={fields.Text} />
    </div>
  )
}

export default Header
