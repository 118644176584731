export const getGoogleMapKey = state => {
  return state.resellers.googleMap.key
}

export const getGoogleMapCenter = state => {
  return state.resellers.googleMap.center
}

export const getGoogleMapZoom = state => {
  return state.resellers.googleMap.zoom
}
