import React from 'react'

export const SortArrowUp = () => {
  return (
    <svg
      width="9px"
      height="21px"
      viewBox="0 0 9 21"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Line 14</title>
      <g
        id="Startpage"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Mobile-startpage-1-1"
          transform="translate(-298.000000, -1357.000000)"
          fill="#00095B"
          fillRule="nonzero"
        >
          <g id="Group-9-Copy" transform="translate(174.000000, 1357.000000)">
            <polygon
              id="Line-14"
              transform="translate(128.500000, 10.500000) rotate(180.000000) translate(-128.500000, -10.500000) "
              points="129 0 129 12 133 12 128.5 21 124 12 128 12 128 0"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
