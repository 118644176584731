import React, { useEffect } from 'react'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import Helmet from 'react-helmet'
import { createScriptTagsInHeadFromHTML } from './utils'

const MetaTags = ({ route, imagePlaceholder, sitecoreContext }) => {
  if (sitecoreContext.pageEditing) return null

  if (typeof window === 'undefined') return null

  useEffect(() => {
    const hasScriptTag = document.getElementsByTagName('noscript')[1]

    // Check if there is a second "<noscript>". That means the first one is an GTM iframe already.
    // This is necessary when switching routes, so it won't add another GTM code snippet.
    if (!hasScriptTag) {
      // Insert "<noscript>" to beginning of body tag.
      document.body.insertAdjacentHTML(
        'afterbegin',
        route?.fields?.gtmModule?.value && route?.fields?.gtmModule?.value
      )
    }

    if (route?.fields?.scriptTags?.value) {
      createScriptTagsInHeadFromHTML(route?.fields?.scriptTags?.value)
    }
  }, [])

  const windowIsUndefined = typeof window === 'undefined'

  return (
    <Helmet>
      <title>{`Ford Sverige ${route?.fields?.pageTitle?.value &&
        `| ${route?.fields?.pageTitle?.value}`}`}</title>
      <meta
        name="title"
        content={`Ford Sverige ${route?.fields?.pageTitle?.value &&
          `| ${route?.fields?.pageTitle?.value}`}`}
      />
      <meta
        name="description"
        content={route?.fields?.metaDescription?.value}
      />
      {!route?.fields?.noIndex?.value ? (
        ''
      ) : (
        <meta name="robots" content="noindex"></meta>
      )}
      <link href={route?.fields?.canonicalURL?.value} rel="canonical"></link>
      <meta
        property="og:title"
        content={`Ford Sverige | ${route?.fields?.ogTitle?.value}`}
      />
      <meta
        property="og:type"
        content={route?.fields?.ogType?.value || 'website'}
      />
      <meta
        property="og:url"
        content={
          route?.fields?.ogUrl?.value ||
          (!windowIsUndefined ? window.location.href : '')
        }
      />
      <meta
        property="og:image"
        content={
          (route?.fields?.ogImage?.value &&
            route?.fields?.ogImage?.value?.src) ||
          imagePlaceholder
        }
      />
      <meta
        property="og:description"
        content={route?.fields?.ogDescription?.value}
      />
    </Helmet>
  )
}

export default withSitecoreContext()(MetaTags)
