import React from 'react'
import { useSelector } from 'react-redux'
import { RadioButtonContainer } from './RadioButtonContainer'
import { getContractLengthsAllIds } from '../../../selectors'
import { Title } from '../components/Title'

export const ContractLengths = ({ translations }) => {
  const contractLengthsAllIds = useSelector(getContractLengthsAllIds)

  const radioButtonsJSX = contractLengthsAllIds.map(contractLengthId => (
    <RadioButtonContainer key={contractLengthId} id={contractLengthId} />
  ))

  return (
    <div className="car-contract__length">
      <Title>{translations.Period.value}</Title>
      <div className="radio-button__container">{radioButtonsJSX}</div>
    </div>
  )
}
