import React from 'react'
import { ImageComponent } from '../ImageComponent'
import { TextLinkComponent } from '../TextLinkComponent'
import { TextComponent } from '../TextComponent'
import { ImageTextLinkComponent } from '../ImageTextLinkComponent'

export const Factory = props => {
  const { Image, Text, Title, Link, AlignText, AlignLink, AlignImage } = props

  if (Image.value.src && !Title.value) {
    return <ImageComponent fullWidth image={Image} />
  }

  if (Title.value && Text.value && Link.value.href && !Image.value.src) {
    return (
      <TextLinkComponent
        alignText={AlignText.value}
        alignLink={AlignLink.value}
        header={Title}
        description={Text}
        linkTitle={Link}
      />
    )
  }

  if (Title.value && Text.value && !Image.value.src) {
    return (
      <TextComponent
        alignText={AlignText.value}
        header={Title}
        description={Text}
      />
    )
  }

  if (Image.value.src && Title.value && Text.value) {
    return (
      <ImageTextLinkComponent
        alignText={AlignText.value}
        alignLink={AlignLink.value}
        alignImage={AlignImage.value}
        header={Title}
        description={Text}
        linkTitle={Link}
        image={Image}
      />
    )
  }

  return null
}
