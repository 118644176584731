import React, { useState, useRef, useEffect } from 'react'
import { t } from 'i18next'

import { useWindowSize } from '../../../../Foundation/hooks/useWindowSize'
import { StepLabel } from './StepLabel'
import { Steps } from './Steps'
import { Price } from './Price'

const StickyHeader = ({ translations }) => {
  const [isSticky, setSticky] = useState(false)
  const ref = useRef(null)
  const { width } = useWindowSize()

  const handleScroll = () => {
    if (ref.current) {
      setSticky(ref.current.getBoundingClientRect().top <= 106)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', () => handleScroll)
    }
  }, [])

  return (
    <div
      className={`sticky-header-wrapper full-width ${
        isSticky && width > 778 ? 'sticky' : ''
      } ${width < 778 ? 'fixed' : ''}`}
      ref={ref}
    >
      <div className={`sticky-header`}>
        <div className="sticky-steps">
          <StepLabel translations={translations} />
          <Steps />
        </div>
        <div className="sticky-price">
          <Price /> {t('currency_per_month')}
        </div>
      </div>
    </div>
  )
}

export default StickyHeader
