import React from 'react'
import { Placeholder } from '@sitecore-jss/sitecore-jss-react'

const Splitter = props => {
  const marginTop = props.params && props.params.MarginTop ? 'mt-5' : ''
  const marginBottom = props.params && props.params.MarginBottom ? 'mb-5' : ''

  return props.params && parseInt(props.params.SplitThree) === 1 ? (
    <div className={`row ${marginTop} ${marginBottom}`}>
      <div className="col-md-4">
        <Placeholder name="jss-left" rendering={props.rendering} />
      </div>
      <div className="col-md-4">
        <Placeholder name="jss-center" rendering={props.rendering} />
      </div>
      <div className="col-md-4">
        <Placeholder name="jss-right" rendering={props.rendering} />
      </div>
    </div>
  ) : (
    <div className={`row ${marginTop} ${marginBottom}`}>
      <div className="col-sm-6">
        <Placeholder name="jss-left" rendering={props.rendering} />
      </div>
      <div className="col-sm-6">
        <Placeholder name="jss-right" rendering={props.rendering} />
      </div>
    </div>
  )
}

export default Splitter
