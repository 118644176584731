const haversineDistance = (mk1, mk2) => {
  const R = 3958.8 // Radius of the Earth in miles
  const rlat1 = mk1.lat * (Math.PI / 180) // Convert degrees to radians
  const rlat2 = mk2.lat * (Math.PI / 180) // Convert degrees to radians
  const difflat = rlat2 - rlat1 // Radian difference (latitudes)
  const difflon = (mk2.lng - mk1.lng) * (Math.PI / 180) // Radian difference (longitudes)

  return (
    2 *
    R *
    Math.asin(
      Math.sqrt(
        Math.sin(difflat / 2) * Math.sin(difflat / 2) +
          Math.cos(rlat1) *
            Math.cos(rlat2) *
            Math.sin(difflon / 2) *
            Math.sin(difflon / 2)
      )
    )
  )
}

export const findNearestMarker = (coords, resellersIds, resellersById) => {
  let distance = 1000000000
  let nearestReseller = resellersById[resellersIds[0]]

  resellersIds.forEach(resellerId => {
    const reseller = resellersById[resellerId]

    const { lat, lng } = reseller

    const point = { lat, lng }

    const calculatedDistance = haversineDistance(coords, point)

    if (distance > calculatedDistance) {
      nearestReseller = reseller
      distance = calculatedDistance
    }
  })

  return nearestReseller
}

export const getAllLocations = (resellersIds, resellersById) =>
  resellersIds.map(resellerId => {
    const reseller = resellersById[resellerId]

    const { lat, lng } = reseller

    return {
      lat,
      lng
    }
  })

export const findMarker = (marker, resellersIds, resellersById) => {
  const currentResellerId = resellersIds.find(resellerId => {
    const reseller = resellersById[resellerId]

    const markerLat = marker.getPosition().lat()
    const markerLng = marker.getPosition().lng()

    return reseller.lat === markerLat && reseller.lng === markerLng
  })

  return resellersById[currentResellerId]
}

export const findNearestMarkers = (copyResellersIds, resellersById, point) => {
  const markers = []
  let times = 0

  while (times < 3) {
    const nearestMarker = findNearestMarker(
      point,
      copyResellersIds,
      resellersById
    )

    const resellerIndex = copyResellersIds.findIndex(
      id => id === nearestMarker.id
    )

    copyResellersIds.splice(resellerIndex, 1)

    markers.push(nearestMarker)

    times += 1
  }

  return markers
}

export const fitBounds = (currentGoogleRef, currentGoogleMapRef, markers) => {
  const bounds = new currentGoogleRef.LatLngBounds()

  markers.forEach(marker => {
    const point = { lat: marker.lat, lng: marker.lng }

    bounds.extend(point)
  })

  currentGoogleMapRef.fitBounds(bounds)
}

export const getPointFromGeocode = results => {
  const geometry = results[0].geometry
  const location = geometry.location
  const lat = location.lat()
  const lng = location.lng()

  return { lat, lng }
}
