import React from 'react'
import { Text } from '@sitecore-jss/sitecore-jss-react'

import { DROPDOWN_DATA } from '../../../../configs'
import { DropdownItem } from './DropdownItem'
import { InputRangeSlider } from '../../components'
import { Chips } from './Chips'

export const FiltersDesktop = ({ translations }) => {
  const dropdowns = DROPDOWN_DATA.map(dropdownItem => (
    <DropdownItem
      key={dropdownItem.type}
      type={dropdownItem.type}
      translations={translations}
    />
  ))

  return (
    <div className="filters">
      <Text
        field={translations.CurrentCampaignsTitle}
        tag="h2"
        className="filter__title"
      />
      <div className="filter__wrapper">
        <div className="filter">
          {dropdowns}
          <InputRangeSlider translations={translations} />
        </div>
        <Chips />
      </div>
    </div>
  )
}
