import React, { useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { Text } from '@sitecore-jss/sitecore-jss-react'
import { t } from 'i18next'

import { carOptionsActions, stepsActions } from '../../actions'
import { CarStepSection } from '../../../../Foundation/components/CarStepSection'
import detectScrollInside from '../../../../Foundation/hooks/detectScrollInside'
import { ColorPanel } from './ColorPanel'
import { CarImage } from './CarImage'
import { ContractLengths } from './ContractLengths'
import { ContractMileages } from './ContractMileages'
import { Options } from './Options'

const CarOptions = ({ fields }) => {
  const dispatch = useDispatch()

  const ref = useRef(null)

  useEffect(() => {
    if (!fields.cars) {
      if (typeof window !== 'undefined') {
        window.location.href = '/'
      }
      return null
    }

    const carOptions = fields.cars

    const mandatoryOptions = []
    carOptions.options
      .filter(filterOption => !!filterOption.isMandatory)
      .forEach(option => {
        mandatoryOptions.push(option.variantId)
      })

    const isCarBaseLongDelivery = carOptions.longDelivery
    const isCarColorLongDelivery = carOptions.colors[0].longDelivery

    const sortedContractMileages = (carOptions.contractMileages || []).sort(
      (a, b) => a.size - b.size
    )

    const configurations = {
      color: carOptions.colors[0].variantId,
      contractLength: carOptions.contractLengths[0].variantId,
      contractLengthSize: carOptions.contractLengths[0].size,
      contractMileage: sortedContractMileages[0].variantId,
      contractMileageSize: sortedContractMileages[0].size,
      carIsLongDelivery: isCarBaseLongDelivery,
      colorIsLongDelivery: isCarColorLongDelivery,
      options: mandatoryOptions
    }

    dispatch(carOptionsActions.setCarOptions(carOptions))
    dispatch(carOptionsActions.setCarSelectedOption(configurations))
    dispatch(stepsActions.setRefForStep(2, ref))
  }, [])

  detectScrollInside(ref, setActiveStep)

  function setActiveStep() {
    dispatch(stepsActions.setActiveStep(2))
  }

  const translations = fields.datasourceItems

  return (
    <CarStepSection refProp={ref}>
      <h1>
        {t('step')} 2 - <Text field={translations.Configure} />
      </h1>
      <CarImage colors={fields?.cars?.colors} />
      <ColorPanel />
      <ContractLengths translations={translations} />
      <ContractMileages translations={translations} />
      <Options translations={translations} />
    </CarStepSection>
  )
}

CarOptions.propTypes = {
  fields: PropTypes.shape({
    cars: PropTypes.shape({
      colors: PropTypes.arrayOf(
        PropTypes.shape({
          variantId: PropTypes.string.isRequired,
          displayName: PropTypes.string.isRequired,
          colorCode: PropTypes.string.isRequired,
          colorStyle: PropTypes.string.isRequired,
          price: PropTypes.number.isRequired
        }).isRequired
      ).isRequired,
      contractLengths: PropTypes.arrayOf(
        PropTypes.shape({
          variantId: PropTypes.string.isRequired,
          description: PropTypes.string.isRequired
        }).isRequired
      ).isRequired,
      contractMileages: PropTypes.arrayOf(
        PropTypes.shape({
          variantId: PropTypes.string.isRequired,
          description: PropTypes.string.isRequired
        }).isRequired
      ).isRequired,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          variantId: PropTypes.string.isRequired,
          displayName: PropTypes.string.isRequired,
          description: PropTypes.string.isRequired,
          image: PropTypes.string,
          price: PropTypes.number.isRequired
        }).isRequired
      ).isRequired
    }).isRequired
  }).isRequired
}

export default CarOptions
