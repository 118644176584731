import React from 'react'
import PropTypes from 'prop-types'

export const AccordionDetails = ({ children, expanded, isPadding = false }) => {
  const paddingClass = isPadding ? 'car-accordion__body--padding' : ''

  return expanded ? (
    <div className={`car-accordion__body ${paddingClass}`}>{children}</div>
  ) : null
}

AccordionDetails.propTypes = {
  children: PropTypes.node.isRequired,
  expanded: PropTypes.bool.isRequired,
  isPadding: PropTypes.bool
}

AccordionDetails.defaultProps = {
  isPadding: false
}
