import React from 'react'
import PropTypes from 'prop-types'

const colorOptions = ['light-blue', 'grey']
const widthOptions = [2]

export const Divider = ({ color = '', width = 1 }) => {
  const colorClass = colorOptions.includes(color) ? color : ''
  const widthClass = widthOptions.includes(width) ? `width-${width}` : ''

  return <div className={`divider ${colorClass} ${widthClass}`} />
}

Divider.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number
}

Divider.defaultProps = {
  color: '',
  width: 1
}
