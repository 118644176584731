import { LABEL_ALL } from '../configs'

export const updateSelected = (label, checked, selected, filter) => {
  const copySelected = [...selected]

  if (label === LABEL_ALL) {
    return checked ? filter : []
  }

  const filterSelectedIndex = copySelected.findIndex(
    filterItem => filterItem === label
  )

  if (filterSelectedIndex === -1) {
    copySelected.push(label)
  } else {
    copySelected.splice(filterSelectedIndex, 1)
  }

  return copySelected
}

export const setCheck = (label, filter, selected) => {
  let check = false

  if (label === LABEL_ALL) {
    if (filter.length === selected.length) {
      check = true
    }
  } else {
    check = selected.find(item => item === label)
  }

  return check
}
