import React from 'react'
import { Image } from '@sitecore-jss/sitecore-jss-react'
import PropTypes from 'prop-types'
import { Content } from './Content'
import { useWindowSize } from '../../../hooks/useWindowSize'

export const Slide = props => {
  const { width } = useWindowSize()

  const { Image: ImageDesktop, ImageMobile } = props.item

  const switchImageByDeviceWidth = () => {
    return width <= 500 ? ImageMobile : ImageDesktop
  }

  const getImageBasedOnDevice = switchImageByDeviceWidth(width)

  return (
    <>
      <Image className="carousel__image" field={getImageBasedOnDevice} />
      <Content item={props.item} />
    </>
  )
}

Slide.propTypes = {
  image: PropTypes.shape({
    value: PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string
    }).isRequired
  }).isRequired,
  imageMobile: PropTypes.shape({
    value: PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string
    }).isRequired
  }).isRequired,
  alignText: PropTypes.shape({
    value: PropTypes.string
  }),
  title: PropTypes.shape({
    value: PropTypes.string
  }),
  subtitle: PropTypes.shape({
    value: PropTypes.string
  }),
  description: PropTypes.shape({
    value: PropTypes.string
  }),
  priceText: PropTypes.shape({
    value: PropTypes.string
  }),
  link: PropTypes.shape({
    value: PropTypes.shape({
      href: PropTypes.string,
      text: PropTypes.string
    }).isRequired
  })
}
