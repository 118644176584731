import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Text } from '@sitecore-jss/sitecore-jss-react'

import { getUserMadeOrder } from '../../selectors'

const ThankYou = props => {
  const backgroundImage = {
    backgroundImage: `url(${props.fields.BackgroundImage.value?.src})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: '50% 70%'
  }

  const userMadeOrder = useSelector(getUserMadeOrder)

  //if (userMadeOrder === 'DummyRefId') return null

  return (
    <div className="full-width" style={backgroundImage}>
      <div className="thanks">
        {' '}
        <Text field={props.fields.Title} tag="h2" className="thanks__title" />
        <Text
          field={props.fields.Description}
          tag="p"
          className="thanks__description"
        />
      </div>
    </div>
  )
}

export default ThankYou
