import React from 'react'
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react'

const Article = ({ fields, params }) => {
  if (!fields) return <></>
  return (
    <div className="article">
      <div className="article__inner">
        <Text className="article--title" tag="h2" field={fields.Title} />
        <RichText className="article--richText" field={fields.RichText} />
      </div>
    </div>
  )
}

export default Article
