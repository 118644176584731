import { stepsTypes } from '../types'

export const stepsActions = Object.freeze({
  setRefForStep: (stepNumber, ref) => dispatch => {
    return dispatch({
      type: stepsTypes.SET_REF_FOR_STEP,
      payload: {
        stepNumber,
        ref
      }
    })
  },
  setActiveStep: stepNumber => (dispatch, getState) => {
    // prevent spam when scrolling to update step
    if (stepNumber !== getState().steps.activeStep) {
      return dispatch({
        type: stepsTypes.SET_ACTIVE_STEP,
        payload: stepNumber
      })
    }
  }
})
