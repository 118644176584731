import React, { useRef } from 'react'
import { useDispatch } from 'react-redux'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'

import { useWindowSize } from '../../../../Foundation/hooks/useWindowSize'
import detectScrollInside from '../../../../Foundation/hooks/detectScrollInside'

import StickyHeader from '../StickyHeader'
import { Dot } from '../../../../Foundation/components/Carousel/Dot'

import { stepsActions } from '../../actions'

const CarCarousel = ({ fields }) => {
  const { width } = useWindowSize()
  const dispatch = useDispatch()
  const ref = useRef(null)

  function setActiveStep() {
    dispatch(stepsActions.setActiveStep(1))
  }

  detectScrollInside(ref, setActiveStep)

  const isShowIndicator =
    width >= 768
      ? fields.carImagesDesktop.length > 1
      : fields.carImagesMobile.length > 1

  return (
    <>
      <div className="full-width" ref={ref}>
        <Carousel
          className="carousel--small"
          infiniteLoop
          useKeyboardArrows
          showArrows={false}
          showStatus={false}
          showThumbs={false}
          showIndicators
          autoPlay
          interval={5000}
          renderIndicator={(clickHandler, isSelected) => (
            <Dot onClick={clickHandler} isSelected={isSelected} />
          )}
        >
          {fields.carImagesDesktop.map((image, i) => (
            <Slide image={image} key={i} />
          ))}
        </Carousel>
      </div>
      <StickyHeader translations={fields.datasourceItems} />
    </>
  )
}

const Slide = ({ image }) => (
  <img className="carousel__image" src={image} alt="" />
)

export default CarCarousel
