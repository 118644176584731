import React from 'react'

export const ArrowRight = ({ color = '#00095B' }) => (
  <svg width="10" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2 1.8l6 6-6 6"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
