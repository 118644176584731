import { stepsTypes } from '../types'
import { STEPS_KEYS } from '../configs/steps'

const initialState = {
  refForFirstStep: null,
  refForSecondStep: null,
  refForThirdStep: null,
  refForFourthStep: null,
  activeStep: 1
}

export default (state = initialState, action) => {
  switch (action.type) {
    case stepsTypes.SET_REF_FOR_STEP:
      const { stepNumber, ref } = action.payload

      const step = STEPS_KEYS[stepNumber]

      return {
        ...state,
        [step]: ref
      }
    case stepsTypes.SET_ACTIVE_STEP:
      return {
        ...state,
        activeStep: action.payload
      }
    default:
      return state
  }
}
