import React from 'react'

const GoogleMaps = props => {
  const url = 'https://www.google.com/maps/embed/v1/place?key='
  return (
    <div>
      <iframe
        width="100%"
        height="450"
        frameBorder="0"
        title="Google maps"
        src={`${url}${props.fields.GoogleAPIKey.value}&q=${props.fields.Address.value}`}
        allowFullScreen
      ></iframe>
    </div>
  )
}

export default GoogleMaps
