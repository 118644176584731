import React from 'react'
import Checkbox from 'react-custom-checkbox'
import PropTypes from 'prop-types'
import { icons } from '../../../Project/ford-to-go/assets/icons'

export const UsefulCheckbox = ({
  label = '',
  onChange,
  checked,
  labelStyle,
  isMandatory
}) => {
  const handleChange = checkedValue => {
    onChange(label, checkedValue)
  }

  const styles = {
    checked: {
      backgroundColor: '#2d96cd'
    },
    checkbox: {
      backgroundColor: '#d8d8d8',
      padding: 2
    },
    isMandatory: {
      opacity: isMandatory ? 0.5 : 1,
      cursor: isMandatory ? 'not-allowed' : 'pointer'
    }
  }

  const checkboxStyle = checked
    ? { ...styles.checkbox, ...styles.checked, ...styles.isMandatory }
    : { ...styles.checkbox }

  return (
    <Checkbox
      icon={<icons.CheckMark color="fff" />}
      checked={checked}
      onChange={handleChange}
      borderColor="#979797"
      borderWidth={1}
      borderRadius={4}
      style={checkboxStyle}
      labelStyle={labelStyle}
      label={label}
      disabled={isMandatory}
    />
  )
}

UsefulCheckbox.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  checked: PropTypes.bool.isRequired
}

UsefulCheckbox.defaultProps = {
  label: ''
}
