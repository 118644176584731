import { STEPS_KEYS } from '../configs/steps'

export const getRefForStep = stepNumber => state => {
  const stepKey = STEPS_KEYS[stepNumber]

  return state.steps[stepKey]
}

export const getActiveStep = state => {
  return state.steps.activeStep
}
