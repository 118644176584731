import React from 'react'
import { Link } from 'react-router-dom'

export const FooterLink = ({ url, description, isExternalLink }) => {
  return isExternalLink ? (
    <a
      className="footer__text footer__link"
      href={url}
      target="_blank"
      rel="noreferrer"
    >
      <span className="footer__link--text">{description}</span>
    </a>
  ) : (
    <Link to={url} className="footer__text footer__link">
      <span className="footer__link--text">{description}</span>
    </Link>
  )
}
