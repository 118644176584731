import React from 'react'
import { Image } from '@sitecore-jss/sitecore-jss-react'

const ImageComponent = ({ fields }) => (
  <div>
    <Image field={fields.Image} className="img-fluid" />
  </div>
)

export default ImageComponent
