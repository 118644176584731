import React from 'react'

export const Cross = () => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>close</title>
    <defs>
      <filter
        x="-9.9%"
        y="-21.2%"
        width="119.9%"
        height="142.4%"
        filterUnits="objectBoundingBox"
        id="filter-1"
      >
        <feOffset dx="5" dy="9" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          stdDeviation="5.5"
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0"
          type="matrix"
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
    <g
      id="Startpage"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="Mobile-Meny" transform="translate(-335.000000, -86.000000)">
        <rect
          id="Rectangle"
          stroke="#979797"
          fill="#D8D8D8"
          x="-0.5"
          y="66.5"
          width="400"
          height="246"
        />
        <g
          id="Group"
          filter="url(#filter-1)"
          transform="translate(-9.000000, 69.000000)"
          fill="#FFFFFF"
        >
          <rect id="Rectangle" x="0" y="0" width="392" height="184" />
        </g>
        <g
          id="close"
          transform="translate(336.000000, 87.000000)"
          stroke="#202020"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        >
          <g id="Shape" transform="translate(-0.001000, 0.000000)">
            <path d="M22,0.002 L0,22.002 M22,22.002 L0.001,5.32907052e-15" />
          </g>
        </g>
      </g>
    </g>
  </svg>
)
