import React from 'react'
import PropTypes from 'prop-types'
import { TextList } from '../../../../Foundation/components/TextList'

const PuffBullets = ({ fields }) => {
  const { Header, Text, TextBullets } = fields

  return <TextList title={Header} description={Text} list={TextBullets} />
}

PuffBullets.propTypes = {
  fields: PropTypes.shape({
    Header: PropTypes.shape({
      value: PropTypes.string.isRequired
    }).isRequired,
    Text: PropTypes.shape({
      value: PropTypes.string.isRequired
    }).isRequired,
    TextBullets: PropTypes.shape({
      value: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
}

export default PuffBullets
