import React from 'react'
import { Image, Text, RichText } from '@sitecore-jss/sitecore-jss-react'

const Blurb = ({ fields }) => {
  if (!fields) return <></>
  const reverse = fields.Reverse.value
  const colorTheme = fields.ColorTheme.value && fields.ColorTheme.value
  const image = fields.Image
  const title = fields.Title
  const text = fields.RichText

  const reverseClass = reverse ? 'reverse' : ''

  return (
    <div className={`blurb blurb--${colorTheme} blurb--${reverseClass}`}>
      <div className={'blurb__inner'}>
        <figure className="blurb__media">
          <picture>
            <Image field={image} className="img-fluid blurb__img" />
          </picture>
        </figure>
        <div className="blurb__content">
          <div className={'blurb__content-inner'}>
            <Text tag="h2" field={title} className="blurb__title" />
            <RichText tag="p" field={text} className="blurb__text" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Blurb
