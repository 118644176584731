export const getCarsAllIds = state => {
  return state.carList.cars.allIds
}

export const getCarsById = state => {
  return state.carList.cars.byId
}

export const getCarFiltersSelected = state => {
  return state.carList.carFiltersSelected
}

export const getPriceFilter = state => {
  return state.carList.priceFilter
}

export const getSortByPrice = state => {
  return state.carList.sortByPrice
}

export const getPrices = state => {
  return state.carList.prices
}

export const getFilterByType = type => state => {
  return state.carList[type]
}

export const getFiltersSelectedByType = type => state => {
  return state.carList.carFiltersSelected[type]
}

export const getQuantityCars = state => {
  return state.carList.quantity
}
