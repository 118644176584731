import React from 'react'
import { useSelector } from 'react-redux'
import { getColorsAllIds } from '../../../selectors'
import { ColorItem } from './ColorItem'

export const ColorPanel = () => {
  const colors = useSelector(getColorsAllIds)

  const colorElements = colors.map(color => (
    <ColorItem key={color} id={color} />
  ))

  return (
    <div className="car-colors">
      <div className="car-colors__inner">{colorElements}</div>
    </div>
  )
}
