import React from 'react'
import { Link } from '@sitecore-jss/sitecore-jss-react'
// note we're aliasing the router's link component name, since it conflicts with JSS' link component
import { Link as RouterLink } from 'react-router-dom'

/** React component that turns Sitecore link values that start with / into react-router route links */
/**
 PLEASE NOTE!!!
 This RoutableSitecoreLink differs from new projects and this shall not be used for new projects.
 */
const RoutableSitecoreLink = ({
  field,
  externalTarget,
  children,
  ...props
}) => {
  const hasValidFieldHref = field && field.value && field.value.href
  const hasValidToHref = props.to
  const isEditing = props.editable && field.editable

  if (externalTarget) {
    return (
      <a href={props.to} className={props.className}>
        {children}
      </a>
    )
  }

  // only want to apply the routing link if not editing (if editing, need to render editable link value)
  if ((hasValidToHref || hasValidFieldHref) && !isEditing) {
    const dummyField = {
      href: props.to,
      title: undefined,
      target: undefined,
      class: undefined
    }
    const value = hasValidToHref ? dummyField : field.value

    // determine if a link is a route or not. This logic may not be appropriate for all usages.
    if (value.href.startsWith('/')) {
      let newHref = value.href

      if (value?.querystring) {
        if (value?.querystring.slice(0, 1) === '?') {
          newHref += value?.querystring
        } else {
          newHref += '?' + value?.querystring
        }
      }

      return (
        <RouterLink
          to={newHref}
          title={value.title}
          target={value.target}
          className={value.class}
          {...props}
        >
          {children}
        </RouterLink>
      )
    }
  }

  return (
    <Link field={field} {...props}>
      {children}
    </Link>
  )
}

export default RoutableSitecoreLink
