import React from 'react'
import { useSelector } from 'react-redux'

import { useWindowSize } from '../../../../../Foundation/hooks/useWindowSize'
import { getConfigurations } from '../../../selectors'

export const CarImage = ({ colors }) => {
  const { width } = useWindowSize()

  const configurations = useSelector(getConfigurations)

  return (
    <div className="car-image">
      {colors
        ?.filter(color => color.variantId === configurations.color)
        .map((selected, i) => {
          return width <= 768 ? (
            <img
              className="car-image__img"
              src={selected.imageMobile}
              alt=""
              key={i}
            />
          ) : (
            <img
              className="car-image__img"
              src={selected.image}
              key={i}
              alt=""
            />
          )
        })}
    </div>
  )
}
