import { getSelectedFiltersKeys } from './filters'
import { STICKERS } from '../configs'

export const filterByPrice = (priceFilter, carsAllIds, carsById) => {
  return priceFilter
    ? carsAllIds.filter(carId => priceFilter >= carsById[carId].price)
    : carsAllIds
}

export const filterCars = (filteredByPrice, carFiltersSelected, carsById) => {
  const carFiltersSelectedKeys = getSelectedFiltersKeys(carFiltersSelected)

  return carFiltersSelectedKeys.length > 0
    ? filteredByPrice.filter(carId => {
        return carFiltersSelectedKeys.every(key =>
          carFiltersSelected[key].some(
            keyEle => carsById[carId][key] === keyEle
          )
        )
      })
    : filteredByPrice
}

export const sortCars = (filteredCars, sortByPrice, carsById) => {
  return filteredCars.sort((carId1, carId2) => {
    if (sortByPrice === 'down') {
      return carsById[carId2].price - carsById[carId1].price
    }

    return carsById[carId1].price - carsById[carId2].price
  })
}

export const getSplashImage = (sticker, width) => {
  if (!STICKERS[sticker]) {
    return
  }

  const { desktop, mobile } = STICKERS[sticker]

  return {
    value: {
      src: width >= 768 ? desktop : mobile,
      alt: ''
    }
  }
}
