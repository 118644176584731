import React from 'react'
import PropTypes from 'prop-types'
import { Accordion } from './Accordion'

const Faq = ({ fields }) => {
  const faqList = fields.ListQuestions.map(question => {
    const { id, fields: questionFields } = question
    const { Title, RichText } = questionFields

    return <Accordion key={id} title={Title} details={RichText} />
  })

  return (
    <div className="faq">
      {/*<div className="faq__title">Vanliga frågor om Privatleasing</div>*/}
      {/*<div className="faq__description">*/}
      {/*  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod*/}
      {/*  tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim*/}
      {/*  veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea*/}
      {/*  commodo consequat.*/}
      {/*</div>*/}
      {faqList}
    </div>
  )
}

Faq.propTypes = {
  fields: PropTypes.shape({
    ListQuestions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        fields: PropTypes.shape({
          Title: PropTypes.shape({
            value: PropTypes.string.isRequired
          }).isRequired,
          RichText: PropTypes.shape({
            value: PropTypes.string.isRequired
          }).isRequired
        }).isRequired
      }).isRequired
    ).isRequired
  }).isRequired
}

export default Faq
