import React from 'react'
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react'
import PropTypes from 'prop-types'
import { Button } from '../../../Button'

export const Content = ({
  item: {
    AlignText,
    ButtonLabel,
    ButtonLink,
    FordModel,
    ModelPrefix,
    PriceText,
    ShortDescription
  }
}) => {
  return (
    <div
      className={`carousel__content ${AlignText.value === 'Left' && 'left'}`}
    >
      <div className="carousel__content__title__wrapper">
        <Text
          className="carousel__content__title"
          field={ModelPrefix}
          tag="div"
        />
        <Text
          className="carousel__content__subtitle"
          field={FordModel}
          tag="div"
        />
      </div>
      <RichText
        className="carousel__content__description"
        field={ShortDescription}
        tag="div"
      />
      <RichText
        className="carousel__content__price"
        field={PriceText}
        tag="div"
      />
      {ButtonLabel.value && (
        <Button url={ButtonLink.value.href} text={ButtonLabel.value} redirect />
      )}
    </div>
  )
}

Content.propTypes = {
  alignText: PropTypes.shape({
    value: PropTypes.string.isRequired
  }).isRequired,
  title: PropTypes.shape({
    value: PropTypes.string.isRequired
  }).isRequired,
  subtitle: PropTypes.shape({
    value: PropTypes.string.isRequired
  }).isRequired,
  description: PropTypes.shape({
    value: PropTypes.string.isRequired
  }).isRequired,
  priceText: PropTypes.shape({
    value: PropTypes.string.isRequired
  }).isRequired,
  link: PropTypes.shape({
    value: PropTypes.shape({
      href: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
}
