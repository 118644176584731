import React from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import {
  getContractLengthsById,
  getSelectedContractLength
} from '../../../../selectors'
import { RadioButton } from '../../components'
import { carOptionsActions } from '../../../../actions'

export const RadioButtonContainer = ({ id }) => {
  const dispatch = useDispatch()

  const { description, variantId } = useSelector(getContractLengthsById(id))

  const selectedContractLengthId = useSelector(getSelectedContractLength)

  const isSelected = selectedContractLengthId === variantId

  const handleClick = () => {
    // do nothing if clicking the same option
    if (!isSelected) {
      dispatch(
        carOptionsActions.setCarSelectedOption({
          contractLength: variantId
        })
      )
    }
  }

  return (
    <>
      <RadioButton
        checked={isSelected}
        label={description}
        onClick={handleClick}
      />
    </>
  )
}

RadioButtonContainer.propTypes = {
  id: PropTypes.string.isRequired
}
