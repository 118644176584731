import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { filterLocations, formatOpeningHours } from '../../../helpers'
import { resellersActions, googleMapActions } from '../../../actions'
import {
  getResellersIds,
  getResellersByIds,
  getSearchText,
  getCurrentResellers,
  getSelectedResellerId
} from '../../../selectors'
import { Reseller } from '../Reseller'
import { SearchSuggestions } from '../SearchSuggestions'

export const FilteredResellers = ({ translations }) => {
  const dispatch = useDispatch()
  const resellersIds = useSelector(getResellersIds)
  const resellersById = useSelector(getResellersByIds)
  const selectedResellerId = useSelector(getSelectedResellerId)
  const searchText = useSelector(getSearchText)
  const currentResellers = useSelector(getCurrentResellers)

  const handleClickSearchSuggestion = e => {
    const reseller = resellersById[e.target.id]

    const { lat, lng } = reseller

    dispatch(resellersActions.setCurrentResellers([reseller]))
    dispatch(resellersActions.searchResellers(''))
    dispatch(googleMapActions.setCenterMap({ lat, lng }))
    dispatch(googleMapActions.setZoomMap(10))
  }

  const handleSelectReseller = e => {
    const resellerId = resellersById[e.currentTarget.id].resellerId

    dispatch(resellersActions.selectReseller(resellerId))
  }

  if (searchText) {
    const { filteredResellers, filterName } = filterLocations(
      resellersIds,
      resellersById,
      searchText
    )

    return (
      <SearchSuggestions
        filteredResellers={filteredResellers}
        filterName={filterName}
        searchText={searchText}
        handleClickSearchSuggestion={handleClickSearchSuggestion}
      />
    )
  }

  if (currentResellers.length > 0) {
    return currentResellers.map(currentReseller => {
      const openHours = formatOpeningHours(currentReseller)

      return (
        <Reseller
          key={currentReseller.id}
          selectedResellerId={selectedResellerId}
          {...currentReseller}
          openHours={openHours}
          handleSelectReseller={handleSelectReseller}
          translations={translations}
        />
      )
    })
  }

  return null
}
