import React from 'react'

export const ArrowDown = ({ color = '2D96CD' }) => (
  <svg
    width="19px"
    height="12px"
    viewBox="0 0 19 12"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Path Copy 3</title>
    <g
      id="Startpage"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        id="Desktop--Vanliga-frågor"
        transform="translate(-1154.000000, -676.000000)"
        stroke={`#${color}`}
        strokeWidth="2"
      >
        <polyline
          id="Path-Copy-3"
          transform="translate(1163.500000, 681.799808) rotate(90.000000) translate(-1163.500000, -681.799808) "
          points="1159.1963 673.299808 1167.8037 681.795558 1159.1963 690.299808"
        />
      </g>
    </g>
  </svg>
)
