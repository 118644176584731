import { Form as JssForm } from '@sitecore-jss/sitecore-jss-react-forms'
import React from 'react'
import { withRouter } from 'react-router-dom'
import { sitecoreApiHost, sitecoreApiKey } from '../../../../temp/config'
import { FieldTypes } from '@sitecore-jss/sitecore-jss-react-forms'
import { createDefaultFieldFactory } from '@sitecore-jss/sitecore-jss-react-forms'

import { icons } from '../../../../Project/ford-to-go/assets/icons'

const defaultFieldFactory = createDefaultFieldFactory()

defaultFieldFactory.setComponent(FieldTypes.Button, props => (
  <button className="button button--border">
    <span className="button__text">{props.field.model.title}</span>
    <icons.ArrowRight />
  </button>
))

const Form = ({ fields, history }) => (
  <JssForm
    form={fields}
    sitecoreApiHost={sitecoreApiHost}
    sitecoreApiKey={sitecoreApiKey}
    onRedirect={url => history.push(url)}
    fieldFactory={defaultFieldFactory}
  ></JssForm>
)

export default withRouter(Form)
