import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { t } from 'i18next'
import { Text } from '@sitecore-jss/sitecore-jss-react'

import { UsefulInputRange } from '../../../../../../Foundation/components/InputRange'
import { carListActions } from '../../../../actions'
import { getPrices, getPriceFilter } from '../../../../selectors'

export const InputRangeSlider = ({ translations }) => {
  const dispatch = useDispatch()
  const prices = useSelector(getPrices)
  const priceFilter = useSelector(getPriceFilter)

  const maxPrice = prices.length > 0 ? Math.max(...prices) : 0

  // const roundedPrice = (Number(maxPrice.toString().charAt(0)) + 1) * 1000

  useEffect(() => {
    if (prices.length === 0) {
      return
    }

    dispatch(carListActions.updatePriceFilter(maxPrice))
  }, [prices])

  const handleChange = value => {
    dispatch(carListActions.updatePriceFilter(value))
  }

  return (
    <div className="range-slider__wrapper">
      <div className="range-slider__title">
        <Text
          field={translations.Price}
          tag="div"
          className="range-slider__title__label"
        />
        <div className="range-slider__title__value">
          {priceFilter} {t('currency_per_month')}
        </div>
      </div>
      <UsefulInputRange
        value={priceFilter}
        maxValue={maxPrice}
        onChange={handleChange}
      />
    </div>
  )
}
