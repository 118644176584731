import React from 'react'
import { Text } from '@sitecore-jss/sitecore-jss-react'

class CarouselThumbnail extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hover: false
    }
  }

  toggleHover = () => {
    this.setState({ hover: !this.state.hover })
  }

  render() {
    const thumbnailStyle = {
      backgroundImage: `url(${this.props.fields.Image.value.src})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      height: '152px',
      maxWidth: '33vw',
      transform: this.state.hover ? 'scale(1.05)' : 'scale(1.00)',
      transition: 'transform .3s ease'
    }

    return (
      <div
        data-target={`#${this.props.id}`}
        data-slide-to={this.props.index}
        className="text-center"
        style={{ flex: '1 1', overflow: 'hidden' }}
        onMouseEnter={this.toggleHover}
        onMouseLeave={this.toggleHover}
      >
        <div style={thumbnailStyle}>
          {!this.props.active && (
            <div
              style={{ backgroundColor: 'rgba(0, 0, 0, 0.3)' }}
              className="w-100 h-100 d-flex flex-column justify-content-end"
            >
              <Text
                tag="p"
                field={this.props.fields.Title}
                className={`text-white font-weight-bold pb-4 mb-0`}
              />
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default CarouselThumbnail
