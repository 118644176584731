import React, { Fragment } from 'react'
import { Text } from '@sitecore-jss/sitecore-jss-react'

import SearchBar from './subs/SearchBar'

// Banner has multiple rendering parameters.

const Banner = props => {
  const { fields, params } = props

  if (!fields) return <></>

  const marginTop = params && params.MarginTop ? 'mt-5' : ''
  const marginBottom = params && params.MarginBottom ? 'mb-5' : ''
  const dark =
    params && params.Dark
      ? 'linear-gradient(to bottom,rgba(0,0,0,0) 40%,rgba(0,0,0,.6) 100%)'
      : 'linear-gradient(to bottom,rgba(0,0,0,0) 40%,rgba(0,0,0,0) 100%)'
  const height = params && params.LargeBanner ? '60vh' : '45vh'
  const textCenter = params && params.CenteredText ? 'center' : {}

  const carstoreBanner =
    params && params.LargeBanner ? 'banner--large' : 'banner--small'

  const background = {
    backgroundImage: `url(${fields.Image.value.src})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover'
  }

  const opacity = {
    background: dark,
    minHeight: height
  }

  const transparent = {
    background: 'none'
  }

  const centerAligned = {
    textAlign: textCenter
  }

  return (
    <Fragment>
      <div
        className={`banner__background full-width ${marginTop} ${marginBottom} ${carstoreBanner}`}
        style={background}
      >
        <div
          className="banner__text w-100 h-100 d-flex align-items-end"
          style={opacity}
        >
          <div
            className={`container ${
              params && params.SearchBar ? 'mt-auto p-4' : ''
            }`}
          >
            <div
              className="jumbotron jumbotron-fluid mb-0 text-white"
              style={transparent}
            >
              <Text
                className="banner__title"
                tag="h1"
                field={fields.Title}
                style={centerAligned}
              />
              <Text
                className="banner__text lead"
                tag="h3"
                field={fields.Text}
                style={centerAligned}
              />
            </div>
          </div>
        </div>
      </div>
      {params && params.SearchBar ? <SearchBar /> : ''}
    </Fragment>
  )
}

export default Banner
