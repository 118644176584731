import React from 'react'
import { Image, Text } from '@sitecore-jss/sitecore-jss-react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { t } from 'i18next'
import NumberFormat from 'react-number-format'

import { Divider } from '../../../../../../Foundation/components/Divider'
import { useWindowSize } from '../../../../../../Foundation/hooks/useWindowSize'
import { getSplashImage } from '../../../../helpers'

export const CarCard = ({
  sticker,
  campaignText,
  brand,
  description,
  ordPrice,
  price,
  carUrl,
  image,
  manufacturer,
  translations
}) => {
  const history = useHistory()
  const { width } = useWindowSize()

  const splashImage = getSplashImage(sticker, width)
  const campaignObj = { value: campaignText }
  const carModelObj = { value: `${manufacturer} ${brand}` }
  const descriptionObj = { value: description }
  const oldPriceObj = { value: `${ordPrice}` }
  const ImageObj = {
    value: {
      src: image,
      alt: description
    }
  }

  const handleClick = () => {
    history.push(carUrl)
  }

  return (
    <div className="card-item loading-animation" onClick={handleClick}>
      <div className="card-item__header">
        <Image className="card-item__image" field={ImageObj} />
        {sticker && (
          <div className="splash-sticker">
            <span className="splash-sticker__text">{sticker}</span>
          </div>
        )}

        {/* <Image className="card-item__splash" field={splashImage} /> */}
      </div>
      <div className="card-item__body">
        <Text className="card-item__campaign" field={campaignObj} tag="div" />
        <Text className="card-item__name" field={carModelObj} tag="div" />
        <Text
          className="card-item__description"
          field={descriptionObj}
          tag="div"
        />
        <Divider color="light-blue" />
        <Text
          className="card-item__price-label"
          field={translations.LeasingPriceStart}
          tag="div"
        />
        <div className="card-item__price">
          <div className="card-item__price--bold">
            <NumberFormat
              value={price}
              thousandSeparator={' '}
              displayType={'text'}
            />{' '}
          </div>
          {t('currency_per_month')}{' '}
        </div>
      </div>
    </div>
  )
}

CarCard.propTypes = {
  campaignText: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  brand: PropTypes.string.isRequired,
  ordPrice: PropTypes.number.isRequired,
  price: PropTypes.number.isRequired,
  sticker: PropTypes.string.isRequired
}
