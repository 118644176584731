import React from 'react'
import { useSelector } from 'react-redux'
import { Text } from '@sitecore-jss/sitecore-jss-react'

import { CarCard } from './CarCard'
import { ShowMore } from '../ShowMore'
import { Divider } from '../../../../../Foundation/components/Divider'

import { filterByPrice, filterCars, sortCars } from '../../../helpers'
import {
  getCarsAllIds,
  getCarsById,
  getCarFiltersSelected,
  getPriceFilter,
  getSortByPrice,
  getQuantityCars
} from '../../../selectors'

export const Cars = ({ translations }) => {
  const carsAllIds = useSelector(getCarsAllIds)
  const carsById = useSelector(getCarsById)
  const carFiltersSelected = useSelector(getCarFiltersSelected)
  const priceFilter = useSelector(getPriceFilter)
  const sortByPrice = useSelector(getSortByPrice)
  const quantityCars = useSelector(getQuantityCars)

  const filteredByPrice = filterByPrice(priceFilter, carsAllIds, carsById)

  const filteredCars = filterCars(filteredByPrice, carFiltersSelected, carsById)

  const carsElements = sortCars(filteredCars, sortByPrice, carsById)
    .slice(0, quantityCars)
    .map(carId => (
      <CarCard
        key={carsById[carId].productId}
        {...carsById[carId]}
        translations={translations}
      />
    ))

  if (carsElements.length === 0) {
    return (
      <div className="container">
        <Text
          field={translations.NoCarsMatched}
          className="my-5 text-center"
          tag="h3"
        />
        <Divider color="grey" />
      </div>
    )
  }

  return (
    <>
      <div className={`cards`}>{carsElements}</div>
      <ShowMore />
    </>
  )
}
