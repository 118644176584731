import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { t } from 'i18next'

import { carOptionsActions } from '../../actions'

const ThankYouGTM = props => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(carOptionsActions.setUserMadeOrder(props.fields.order.refId))

    if (props.fields.order.refId !== 'DummyRefId') {
      const products = []

      props.fields.order.products.map(product => {
        products.push(product)
      })

      window.dataLayer.push({
        event: 'Purchase',
        ecommerce: {
          currencyCode: 'SEK',
          purchase: {
            actionField: {
              id: props.fields.order.refId,
              revenue: props.fields.order.revenue
            },
            products: products
          }
        }
      })
    }
  }, [])

  return <></>
}

export default ThankYouGTM
