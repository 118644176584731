import React from 'react'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'
import PropTypes from 'prop-types'
import { Slide } from './Slide'
import { Dot } from './Dot'

export const UsefulCarousel = ({ items = [], large, small }) => {
  const isShowIndicator = items.length > 1

  const slides = items
    .filter(image => image.fields.Image.value.src)
    .map(image => {
      return <Slide key={image.id} item={image.fields} />
    })

  return (
    <Carousel
      className={`${
        large ? 'carousel--large' : 'carousel--small'
      } carousel--shadow`}
      infiniteLoop
      useKeyboardArrows
      autoPlay
      showArrows={false}
      showStatus={false}
      showThumbs={false}
      interval={7000}
      showIndicators={isShowIndicator}
      renderIndicator={(clickHandler, isSelected) => (
        <Dot onClick={clickHandler} isSelected={isSelected} />
      )}
    >
      {slides}
    </Carousel>
  )
}

UsefulCarousel.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      fields: PropTypes.shape({
        Image: PropTypes.shape({
          value: PropTypes.shape({
            src: PropTypes.string.isRequired,
            alt: PropTypes.string.isRequired
          }).isRequired
        }).isRequired,
        ImageMobile: PropTypes.shape({
          value: PropTypes.shape({
            src: PropTypes.string.isRequired,
            alt: PropTypes.string.isRequired
          }).isRequired
        }).isRequired,
        AlignText: PropTypes.shape({
          value: PropTypes.string
        }),
        Title: PropTypes.shape({
          value: PropTypes.string
        }),
        Title2: PropTypes.shape({
          value: PropTypes.string
        }),
        Text: PropTypes.shape({
          value: PropTypes.string
        }),
        PriceText: PropTypes.shape({
          value: PropTypes.string
        }),
        Link: PropTypes.shape({
          href: PropTypes.string,
          text: PropTypes.string
        })
      }).isRequired
    })
  ).isRequired
}
