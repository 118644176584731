import React, { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { Text } from '@sitecore-jss/sitecore-jss-react'
import { t } from 'i18next'

import { resellersActions, googleMapActions, stepsActions } from '../../actions'

import { GoogleMap } from './GoogleMap'
import { SearchInput } from './SearchInput'
import { CurrentLocation } from './CurrentLocation'
import { FilteredResellers } from './FilteredResellers'

import { CarStepSection } from '../../../../Foundation/components/CarStepSection'
import detectScrollInside from '../../../../Foundation/hooks/detectScrollInside'

const Resellers = ({ fields }) => {
  const dispatch = useDispatch()
  const ref = useRef(null)
  const googleRef = useRef(null)
  const googleMapRef = useRef(null)

  function setActiveStep() {
    dispatch(stepsActions.setActiveStep(4))
  }

  detectScrollInside(ref, setActiveStep)

  useEffect(() => {
    const { api, resellers } = fields

    dispatch(resellersActions.setResellers(resellers))
    dispatch(googleMapActions.setGoogleKey(api))
    dispatch(stepsActions.setRefForStep(4, ref))
  }, [])

  const refs = { googleRef, googleMapRef }

  const translations = fields.datasourceItems

  return (
    <CarStepSection refProp={ref}>
      <h1>
        {t('step')} 4 - <Text field={translations.Order} />
      </h1>
      <Text
        field={translations.Delivery}
        className="resellers__title"
        tag="p"
      />
      <Text
        field={translations.ChooseReseller}
        className="resellers__subtitle"
        tag="p"
      />
      <div className="resellers__row">
        <div className="resellers__column">
          <SearchInput
            googleRef={googleRef}
            googleMapRef={googleMapRef}
            translations={translations}
          />
          <CurrentLocation translations={translations} />
          <FilteredResellers translations={translations} />
        </div>
        <div className="resellers__column">
          <GoogleMap ref={refs} />
        </div>
      </div>
    </CarStepSection>
  )
}

Resellers.propTypes = {
  fields: PropTypes.shape({
    api: PropTypes.string.isRequired,
    resellers: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        lat: PropTypes.number.isRequired,
        lng: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        zip: PropTypes.string,
        city: PropTypes.string.isRequired,
        streetAddress: PropTypes.string.isRequired,
        phoneNumber: PropTypes.string.isRequired,
        monOpenHours: PropTypes.string.isRequired,
        tuesOpenHours: PropTypes.string.isRequired,
        wedOpenHours: PropTypes.string.isRequired,
        thursOpenHours: PropTypes.string.isRequired,
        friOpenHours: PropTypes.string.isRequired,
        satOpenHours: PropTypes.string.isRequired,
        sunOpenHours: PropTypes.string.isRequired
      }).isRequired
    ).isRequired
  }).isRequired
}

export default Resellers
