import React from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import {
  getContractMileagesById,
  getSelectedContractMileage
} from '../../../../selectors'
import { RadioButton } from '../../components'
import { carOptionsActions } from '../../../../actions'

export const RadioButtonContainer = ({ id, allowedMileagesList }) => {
  const dispatch = useDispatch()
  const { description, variantId, size } = useSelector(
    getContractMileagesById(id)
  )
  const selectedContractMileageId = useSelector(getSelectedContractMileage)

  const isSelected = selectedContractMileageId === variantId

  const handleClick = () => {
    dispatch(
      carOptionsActions.setCarSelectedOption({ contractMileage: variantId })
    )
  }

  return (
    allowedMileagesList.some(item => item.mileage === size) && (
      <RadioButton
        checked={isSelected}
        label={description}
        onClick={handleClick}
      />
    )
  )
}

RadioButtonContainer.propTypes = {
  id: PropTypes.string.isRequired
}
