import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import NumberFormat from 'react-number-format'

import { getTotalPrice } from '../../../selectors'

export const Price = () => {
  const price = useSelector(getTotalPrice)

  const [animate, setAnimate] = useState(false)

  useEffect(() => {
    setAnimate(true)

    setTimeout(() => {
      setAnimate(false)
    }, 300)
  }, [price])

  return (
    <span
      className={`sticky-price__big ${
        animate ? 'sticky-price__big--animate' : ''
      }`}
    >
      <NumberFormat
        value={price}
        thousandSeparator={' '}
        displayType={'text'}
      />
    </span>
  )
}
