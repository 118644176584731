import React from 'react'
import { useSelector } from 'react-redux'
import { getOptionsAllIds } from '../../../selectors'
import { AccordionItem } from './AccordionItem'
import { Title } from '../components/Title'

export const Options = ({ translations }) => {
  const optionsIds = useSelector(getOptionsAllIds)

  const accordionsJSX = optionsIds.map(optionId => (
    <AccordionItem key={optionId} id={optionId} />
  ))

  return (
    <>
      <Title>{translations.Options.value}</Title>
      {accordionsJSX}
    </>
  )
}
