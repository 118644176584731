import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { icons } from '../../../Project/ford-to-go/assets/icons'

export const Button = ({
  url = '#',
  text,
  onClick = () => {},
  border = false,
  disabled,
  redirect
}) =>
  redirect ? (
    <a
      className={`button ${border ? 'button--border' : ''} ${
        disabled ? 'button--disabled' : ''
      }`}
      href={url}
    >
      {text}

      <icons.ArrowRight color={disabled ? '#6e6e6e' : undefined} />
    </a>
  ) : (
    <button
      className={`button ${border ? 'button--border' : ''} ${
        disabled ? 'button--disabled' : ''
      }`}
      role="button"
      tabIndex="0"
      onKeyPress={onClick}
      onClick={onClick}
    >
      {text}

      <icons.ArrowRight color={disabled ? '#6e6e6e' : undefined} />
    </button>
  )

Button.propTypes = {
  url: PropTypes.string,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  border: PropTypes.bool
}

Button.defaultProps = {
  url: '#',
  border: false,
  onClick: () => {}
}
