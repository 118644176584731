import React from 'react'
import { Photo } from '../components'
import { TextLinkComponent } from '../TextLinkComponent'

export const ImageTextLinkComponent = ({
  alignText,
  alignLink,
  alignImage,
  header,
  description,
  linkTitle,
  image
}) => {
  const revertClass = alignImage === 'Left' ? '' : 'reverse'

  return (
    <div className={`image-text__row ${revertClass}`}>
      <div className="image-text__column">
        <Photo image={image} />
      </div>
      <div className="image-text__column">
        <TextLinkComponent
          alignText={alignText}
          alignLink={alignLink}
          header={header}
          description={description}
          linkTitle={linkTitle}
        />
      </div>
    </div>
  )
}
