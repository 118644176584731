import React, { forwardRef } from 'react'
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react'
import PropTypes from 'prop-types'

export const TextList = forwardRef(
  ({ title, subTitle, description, list }, ref) => (
    <div ref={ref} className="text-list">
      <Text className="text-list__title" field={title} tag="div" />
      {subTitle ? (
        <Text className="text-list__subtitle" field={subTitle} tag="div" />
      ) : null}
      <RichText
        className="text-list__description"
        field={description}
        tag="p"
      />
      <RichText className="text-list__list" field={list} tag="div" />
    </div>
  )
)

TextList.propTypes = {
  title: PropTypes.shape({
    value: PropTypes.string.isRequired
  }).isRequired,
  subTitle: PropTypes.shape({
    value: PropTypes.string.isRequired
  }),
  description: PropTypes.shape({
    value: PropTypes.string.isRequired
  }).isRequired,
  list: PropTypes.shape({
    value: PropTypes.string.isRequired
  }).isRequired
}
