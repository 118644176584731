import React from 'react'

export const SortArrowDown = () => {
  return (
    <svg
      width="9px"
      height="21px"
      viewBox="0 0 9 21"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Line 14</title>
      <g
        id="Startpage"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Mobile-startpage-1-1"
          transform="translate(-338.000000, -1364.000000)"
          fill="#00095B"
          fillRule="nonzero"
        >
          <g id="Group-9-Copy" transform="translate(239.000000, 1364.000000)">
            <path
              id="Line-14"
              d="M104,0 L104,12 L108,12 L103.5,21 L99,12 L103,12 L103,0 L104,0 Z"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
