import { getCarId } from '../selectors'

export const createProductObject = (
  productId,
  productCategory,
  variantId = ''
) => ({
  ProductId: productId,
  Quantity: 1,
  VariantId: variantId,
  ProductCategory: productCategory
})

export const createReadableProductObject = reduxState => {
  const readableProductObject = {}

  readableProductObject.carBrand = 'Ford'
  readableProductObject.carModel = reduxState.carOptions.data.description

  readableProductObject.dealer = reduxState.resellers.currentResellers.find(
    item => item.resellerId === reduxState.resellers.selectedResellerId
  ).name

  readableProductObject.accessories =
    reduxState.carOptions.configurations.optionsReadableIds
  readableProductObject.priceAccessories =
    reduxState.carOptions.configurations.optionsPrice

  readableProductObject.leasingMileage =
    reduxState.carOptions.configurations.contractMileageSize
  readableProductObject.leasingPeriod =
    reduxState.carOptions.configurations.contractLengthSize
  readableProductObject.leasingPrice =
    reduxState.carOptions.configurations.carBasePrice
  readableProductObject.totalPrice = reduxState.carOptions.totalPrice

  return readableProductObject
}
