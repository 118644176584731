import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { settings } from '../../../../../../Foundation/Utility/constants.js'

import searchIconLeft from '../../../../assets/search-icon2.svg'
import searchIconRight from '../../../../assets/search-icon.svg'

const SearchBar = props => {
  const [text, setText] = useState('')

  const searchNow = event => {
    let dynamicRouteNameSiteCore

    if (settings) {
      settings.forEach(element => {
        if (element.searchPage) {
          dynamicRouteNameSiteCore = element.searchPage.jss.value.href
        }
      })
    }

    event.preventDefault()
    props.history.push(`${dynamicRouteNameSiteCore}?text=${text}`)
  }

  const handleChange = event => {
    setText(event.target.value)
  }

  return (
    <form onSubmit={searchNow} className="hero-search-form">
      <div className="container d-flex justify-content-center">
        <div className="input-group hero-search__banner">
          <div className="input-group-prepend p-4 border-0">
            <span className="btn input-group-text bg-white border-0">
              <img src={searchIconLeft} alt="" width="35" />
            </span>
          </div>
          <input
            type="text"
            className="hero-search__banner-input form-control form-control-lg border-0"
            id="homepage-search"
            name="searchText"
            placeholder={'Sök efter din nästa bil här'}
            onChange={handleChange}
          ></input>
          <div className="input-group-append border-0 banner-search-bar-button">
            <button
              className="btn hero-search__cta hero-search__cta__filled-button text-uppercase"
              type="submit"
            >
              <span className="d-none d-lg-inline-block mr-2">Sök</span>{' '}
              <img
                src={searchIconRight}
                className="banner-search-bar-svg svg--white"
                alt=""
              />{' '}
            </button>
          </div>
        </div>
      </div>
    </form>
  )
}

export default withRouter(SearchBar)
