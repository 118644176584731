import React from 'react'
import { t } from 'i18next'

import Logo from '../../assets/images/Logo/fordlogo.svg'
import { icons } from '../../assets/icons'

// Renders a route-not-found message when no route is available from Sitecore
// The JSS equivalent of a 404 Not Found page.

// This is invoked from RouteHandler when Sitecore returns no valid route data.
// The NotFound component receives the Layout Service Context data, but no route data.
// This can be used to power parts of your site, such as navigation, from LS context additions
// without losing the ability to render them on your 404 pages :)

const NotFound = () => (
  <>
    <div className="navigation justify-content-lg-start justify-content-center">
      <div className="navigation__wrapper py-1 py-lg-3">
        <div className="logo">
          <a href="/">
            <img src={Logo} className="logo__image"></img>
          </a>
        </div>
      </div>
    </div>
    <div className="not-found">
      <icons.Cognito />
      <h1 className="not-found__title">{t('something_went_wrong')}</h1>
      <p className="not-found__subtitle">{t('try_again')}</p>
    </div>
  </>
)

export default NotFound
