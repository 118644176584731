import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Text } from '@sitecore-jss/sitecore-jss-react'
import { findNearestMarker } from '../../../helpers'

import { resellersActions, googleMapActions } from '../../../actions'
import { getResellersIds, getResellersByIds } from '../../../selectors'

export const CurrentLocation = ({ translations }) => {
  const dispatch = useDispatch()
  const resellersIds = useSelector(getResellersIds)
  const resellersById = useSelector(getResellersByIds)

  const getGeolocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        const { latitude: lat, longitude: lng } = position.coords

        const point = { lat, lng }

        const nearestMarker = findNearestMarker(
          point,
          resellersIds,
          resellersById
        )

        dispatch(resellersActions.setCurrentResellers([nearestMarker]))
        dispatch(
          googleMapActions.setCenterMap({
            lat: nearestMarker.lat,
            lng: nearestMarker.lng
          })
        )
        dispatch(googleMapActions.setZoomMap(10))
      })
    }
  }

  return (
    <div
      className="resellers__location"
      role="button"
      tabIndex="0"
      onKeyPress={getGeolocation}
      onClick={getGeolocation}
    >
      <Text field={translations.FindMyLocation} /> &#62;
    </div>
  )
}
