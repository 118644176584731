import React from 'react'
import { Image } from '@sitecore-jss/sitecore-jss-react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

export const Logo = ({ logo }) => {
  return (
    <div className="logo">
      <Link to="/">
        <Image className="logo__image" field={logo.value} />
      </Link>
    </div>
  )
}

Logo.propTypes = {
  logo: PropTypes.shape({
    value: PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
}
