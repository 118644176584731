import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Text } from '@sitecore-jss/sitecore-jss-react'

import { carListActions } from '../../../actions'
import { sortOptions, sortArrow } from '../../../configs'
import { getSortByPrice } from '../../../selectors'

export const SortPrice = ({ translations }) => {
  const dispatch = useDispatch()
  const sortByPrice = useSelector(getSortByPrice)

  const handleClick = () => {
    const sortOption = sortOptions[sortByPrice]

    dispatch(carListActions.sortCars(sortOption))
  }

  const SortIcon = sortArrow[sortByPrice]

  return (
    <div className="filter-quick__wrapper">
      <div
        className="filter-quick"
        role="button"
        tabIndex="0"
        onKeyPress={handleClick}
        onClick={handleClick}
      >
        <Text
          field={translations.SortPrice}
          tag="div"
          className="filter-quick__label"
        />
        <SortIcon />
      </div>
    </div>
  )
}
