import React, { useRef, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { CarStepSection } from '../../../../Foundation/components/CarStepSection'
import { stepsActions } from '../../actions'

import detectScrollInside from '../../../../Foundation/hooks/detectScrollInside'

const CarInformation = ({ fields }) => {
  const { carName, carDescription, carPresentation } = fields

  const dispatch = useDispatch()

  const ref = useRef(null)

  function setActiveStep() {
    dispatch(stepsActions.setActiveStep(1))
  }

  detectScrollInside(ref, setActiveStep)

  useEffect(() => {
    dispatch(stepsActions.setRefForStep(1, ref))
  }, [])

  return (
    <CarStepSection noBorder refProp={ref}>
      <h1 className="mb-0">{carName}</h1>

      <div className="car-information-model">{carDescription}</div>

      <div
        className="car-information-equipments"
        dangerouslySetInnerHTML={{ __html: carPresentation }}
      ></div>
    </CarStepSection>
  )
}

export default CarInformation
