import React, { useEffect, useState } from 'react'
import {
  Placeholder,
  VisitorIdentification
} from '@sitecore-jss/sitecore-jss-react'
import { useLocation } from 'react-router-dom'
import MetaTags from '../../Feature/feature-module-hedin-common/components/MetaTags'
// Using bootstrap is completely optional. It's used here to provide a clean layout for samples,
// without needing extra CSS in the sample app. Remove it in package.json as well if it's removed here.
import './assets/styles/app.scss'
/*
  APP LAYOUT
  This is where the app's HTML structure and root placeholders should be defined.

  All routes share this root layout by default (this could be customized in RouteHandler),
  but components added to inner placeholders are route-specific.
*/

export const getPageType = path => {
  const carShowPage = new RegExp('\\/bilar\\/[a-zA-Z0-9]+\\/', 'gm')
  const siteShowPage = new RegExp('\\/hitta-oss\\/[a-zA-Z0-9]+\\/', 'gm')
  const siteAltShowPage = new RegExp('\\/anlaggningar\\/[a-zA-Z0-9]+\\/', 'gm')

  if (carShowPage.test(path)) {
    return 'Product Detail Page'
  }

  // TODO: check if below is the one to use or not?
  if (siteShowPage.test(path)) {
    return 'Site Detail Page'
  }

  if (siteAltShowPage.test(path)) {
    return 'Site Detail Page'
  }

  return 'Page'
}

const getEnvironmentInfo = () => {
  switch (window.location.host) {
    case 'fordtogo-test.hedinbil.se':
      return 'Test'

    case 'fordtogo-stage.hedinbil.se':
      return 'Quality Assurance'

    case 'fordtogo-preprod.hedinbil.se':
      return 'Production'

    case 'www.ford2go.se':
      return 'Production'

    case 'ford2go.se':
      return 'Production'

    default:
      return 'Development'
  }
}

const Layout = props => {
  const { route } = props
  const location = useLocation()
  const [routeChangeCount, setRouteChangeCount] = useState(0)

  useEffect(() => {
    // Wait 200ms for nested component to have updated document.title as sometimes it could go too quick.
    setTimeout(() => {
      // If first load then it's a "Page Load" otherwise "Virtual Page Load" for all React router changes.
      const pageInfoTrigger =
        routeChangeCount === 0 ? 'Page Load' : 'Virtual Page Load'

      // Check if 404 hero is on page, if so then send a 404 event.
      const has404Hero = document.getElementById('page-not-found-hero')
      const is404Page = has404Hero ? '404' : '200'

      const environmentName = getEnvironmentInfo()
      const isProduction = environmentName === 'Production'

      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'Page Impression',
        environmentInfo: {
          name: environmentName,
          platform: 'Sitecore',
          isProduction: isProduction
        },
        pageInfo: {
          url: window.location.href,
          canonicalUrl: window.location.origin + window.location.pathname,
          virtualUrlPath: location.pathname,
          title: document.title,
          type: getPageType(location.pathname),
          trigger: pageInfoTrigger,
          httpStatusCode: is404Page
        }
      })

      setRouteChangeCount(routeChangeCount + 1)
    }, 200)
  }, [route])

  return (
    <React.Fragment>
      {/* react-helmet enables setting <head> contents, like title and OG meta tags */}
      <MetaTags route={route} />

      {/*
      VisitorIdentification is necessary for Sitecore Analytics to determine if the visitor is a robot.
      If Sitecore XP (with xConnect/xDB) is used, this is required or else analytics will not be collected for the JSS app.
      For XM (CMS-only) apps, this should be removed.

      VI detection only runs once for a given analytics ID, so this is not a recurring operation once cookies are established.
    */}
      <VisitorIdentification />

      <Placeholder name="jss-header" rendering={route} />

      <div className="page-wrapper">
        <Placeholder name="jss-main" rendering={route} />
      </div>

      <Placeholder name="jss-footer" rendering={route} />
    </React.Fragment>
  )
}

export default Layout
