import { FILTER_NAMES, getWeekDayKey } from '../configs'

export const filterLocations = (resellersIds, resellersById, location) => {
  let filter = ''

  const filteredResellers = resellersIds.filter(resellerId => {
    const reseller = resellersById[resellerId]

    return FILTER_NAMES.some(filterName => {
      if (
        reseller[filterName].toLowerCase().indexOf(location.toLowerCase()) > -1
      ) {
        filter = filterName

        return true
      }

      return false
    })
  })

  return { filteredResellers, filterName: filter }
}

export const highlightSearchWord = (text, searchText) => {
  const wordIndex = text.toLowerCase().indexOf(searchText.toLowerCase())

  const firstPartOfText = text.slice(0, wordIndex)
  const secondPartOfText = text.slice(wordIndex, wordIndex + searchText.length)
  const thirdPartOfText = text.slice(wordIndex + searchText.length)

  return { firstPartOfText, secondPartOfText, thirdPartOfText }
}

export const formatOpeningHours = currentReseller => {
  const weekday = new Date().toLocaleString('default', { weekday: 'short' })
  const weekDayKey = getWeekDayKey[weekday]

  return currentReseller[weekDayKey]
}
