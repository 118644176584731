import React, { useState } from 'react'
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react'
import PropTypes from 'prop-types'
import { AccordionSummary } from './AccordionSummary'
import { AccordionDetails } from './AccordionDetails'
import { icons } from '../../../assets/icons'

export const Accordion = ({ title, details }) => {
  const [expanded, setExpanded] = useState(false)

  const ArrowIcon = expanded ? icons.ArrowUp : icons.ArrowDown

  const handleClick = () => {
    setExpanded(!expanded)
  }

  return (
    <div className={`accordion ${expanded ? 'open' : ''}`}>
      <AccordionSummary
        expanded={expanded}
        icon={<ArrowIcon color="2d96cd" className="accordion__summary__icon" />}
        onClick={handleClick}
      >
        <Text className="accordion__summary__title" field={title} tag="div" />
      </AccordionSummary>
      <AccordionDetails expanded={expanded}>
        <RichText field={details} />
      </AccordionDetails>
    </div>
  )
}

Accordion.propTypes = {
  title: PropTypes.shape({
    value: PropTypes.string.isRequired
  }).isRequired,
  details: PropTypes.shape({
    value: PropTypes.string.isRequired
  }).isRequired
}
