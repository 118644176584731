import React from 'react'

const EmbeddedVideo = ({ fields, params }) => {
  const iframeStyle = {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    border: 0
  }
  const iframeContainerStyle = {
    overflow: 'hidden',
    paddingTop: '56.25%',
    position: 'relative'
  }

  const marginTop = params && params.MarginTop ? 'mt-5' : ''
  const marginBottom = params && params.MarginBottom ? 'mb-5' : ''

  return (
    <div
      className={`${marginTop} ${marginBottom}`}
      style={iframeContainerStyle}
    >
      {fields.Source &&
        fields.Source.value &&
        fields.Source.value.url &&
        fields.Source.value.url.length > 0 && (
          <iframe
            style={iframeStyle}
            src={fields.Source.value.url}
            title={fields.Title.value}
            webkitallowfullscreen=""
            mozallowfullscreen=""
            allowFullScreen=""
            frameBorder="0"
          ></iframe>
        )}
    </div>
  )
}

export default EmbeddedVideo
