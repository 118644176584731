import { carListTypes } from '../types'
import { getSelectedFiltersKeys } from '../helpers'

const initialState = {
  cars: {
    byId: {},
    allIds: []
  },
  carType: [],
  fuel: [],
  gearBox: [],
  brand: [],
  prices: [],
  carFiltersSelected: {
    carType: [],
    fuel: [],
    gearBox: [],
    brand: []
  },
  priceFilter: 0,
  sortByPrice: 'down',
  quantity: 6
}

const removeCarsFilters = (carFiltersSelected, label) => {
  const copyCarFiltersSelected = { ...carFiltersSelected }

  const carFilteredKeys = getSelectedFiltersKeys(copyCarFiltersSelected)

  carFilteredKeys.some(carFilteredKey => {
    const filtersCopyCarFiltersSelected = copyCarFiltersSelected[
      carFilteredKey
    ].filter(filtersSelectedItem => filtersSelectedItem !== label)

    if (
      filtersCopyCarFiltersSelected.length !==
      copyCarFiltersSelected[carFilteredKey].length
    ) {
      copyCarFiltersSelected[carFilteredKey] = filtersCopyCarFiltersSelected

      return true
    }

    return false
  })

  return copyCarFiltersSelected
}

export default (state = initialState, action) => {
  switch (action.type) {
    case carListTypes.SET_CAR_LIST:
      const {
        carTypes,
        fuels,
        gearBoxes,
        brands,
        cars,
        ...rest
      } = action.payload

      const normalizedCars = {
        byId: {},
        allIds: []
      }

      cars.forEach(car => {
        normalizedCars.allIds.push(car.productId)

        normalizedCars.byId[car.productId] = car
      })

      return {
        ...state,
        ...rest,
        cars: normalizedCars,
        carType: carTypes,
        fuel: fuels,
        gearBox: gearBoxes,
        brand: brands
      }
    case carListTypes.UPDATE_CARS_FILTER:
      const { type, options } = action.payload

      return {
        ...state,
        carFiltersSelected: {
          ...state.carFiltersSelected,
          [type]: options
        }
      }
    case carListTypes.UPDATE_PRICE_FILTER:
      return {
        ...state,
        priceFilter: action.payload
      }
    case carListTypes.REMOVE_CARS_FILTER:
      const copyCarFiltersSelected = { ...state.carFiltersSelected }

      const updatedCarFiltersSelected = removeCarsFilters(
        copyCarFiltersSelected,
        action.payload
      )

      return {
        ...state,
        carFiltersSelected: updatedCarFiltersSelected
      }
    case carListTypes.SORT_CARS:
      return {
        ...state,
        sortByPrice: action.payload
      }
    case carListTypes.SET_QUANTITY_CARS:
      let newQuantity = state.quantity + action.payload
      const quantityCars = state.cars.allIds.length

      if (quantityCars < newQuantity) {
        newQuantity = quantityCars
      }

      return {
        ...state,
        quantity: newQuantity
      }
    default:
      return state
  }
}
