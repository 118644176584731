import React from 'react'
import {
  Text,
  RichText,
  Image as ImageSiteCore
} from '@sitecore-jss/sitecore-jss-react'
import PropTypes from 'prop-types'

const SellProcess = ({ fields }) => {
  const { Image, AlignText, Title, RichText: RichTextProp } = fields

  return (
    <div className="full-width">
      <div
        className={`sell-process ${AlignText.value === 'Left' ? 'revert' : ''}`}
      >
        <div className="sell-process__column">
          <ImageSiteCore className="sell-process__image" field={Image} />
        </div>
        <div className="sell-process__column content">
          <Text className="sell-process__title" field={Title} tag="h2" />
          <RichText field={RichTextProp} />
        </div>
      </div>
    </div>
  )
}

SellProcess.propTypes = {
  fields: PropTypes.shape({
    Image: PropTypes.shape({
      value: PropTypes.shape({
        src: PropTypes.string.isRequired,
        alt: PropTypes.string.isRequired
      }).isRequired
    }).isRequired,
    AlignText: PropTypes.shape({
      value: PropTypes.string.isRequired
    }).isRequired,
    Title: PropTypes.shape({
      value: PropTypes.string.isRequired
    }).isRequired,
    RichText: PropTypes.shape({
      value: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
}

export default SellProcess
