import React from 'react'

export const Filter = () => (
  <svg
    width="26px"
    height="26px"
    viewBox="0 0 26 26"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>icon/filter</title>
    <defs>
      <filter id="filter-1">
        <feColorMatrix
          in="SourceGraphic"
          type="matrix"
          values="0 0 0 0 0.000000 0 0 0 0 0.035294 0 0 0 0 0.356863 0 0 0 1.000000 0"
        />
      </filter>
    </defs>
    <g
      id="icon/filter"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g filter="url(#filter-1)" id="filter-6576">
        <g transform="translate(13.000000, 13.000000) rotate(90.000000) translate(-13.000000, -13.000000) translate(5.000000, 3.000000)">
          <path
            d="M2,20 C1.448,20 1,19.6093969 1,19.1281182 L1,11.8718818 C1,11.3906031 1.448,11 2,11 C2.552,11 3,11.3906031 3,11.8718818 L3,19.1281182 C3,19.6093969 2.552,20 2,20 Z"
            id="Path"
            fill="#000000"
            fillRule="nonzero"
          />
          <path
            d="M14,13 C13.448,13 13,12.6642163 13,12.2504829 L13,0.749517138 C13,0.335783678 13.448,0 14,0 C14.552,0 15,0.335783678 15,0.749517138 L15,12.2501081 C15,12.6642163 14.5525,13 14,13 Z"
            id="Path"
            fill="#000000"
            fillRule="nonzero"
          />
          <path
            d="M8,20 C7.448,20 7,19.7437635 7,19.4280435 L7,7.57195653 C7,7.25623653 7.448,7 8,7 C8.552,7 9,7.25623653 9,7.57195653 L9,19.4280435 C9,19.7440495 8.552,20 8,20 Z"
            id="Path"
            fill="#000000"
            fillRule="nonzero"
          />
          <path
            d="M8,8 C6.89701858,8 6,7.10275798 6,6.00014402 C6,4.89753006 6.89730664,4 8,4 C9.10269336,4 10,4.89724202 10,6.00014402 C10,7.10304601 9.10269336,8 8,8 Z M8,5 C7.44852192,5 7,5.44878546 7,6.00016987 C7,6.55155427 7.44852192,7 8,7 C8.55147808,7 9,6.55155427 9,6.00016987 C9,5.44878546 8.55147808,5 8,5 Z"
            id="Shape"
            fill="#000000"
            fillRule="nonzero"
          />
          <path
            d="M1.99985598,12 C0.896953986,12 0,11.1026934 0,10 C0,8.89701858 0.897242025,8 1.99985598,8 C3.10246994,8 4,8.89730664 4,10 C4,11.1026934 3.10275798,12 1.99985598,12 Z M1.99985598,9.15223967 C1.5323684,9.15223967 1.15215669,9.53247876 1.15215669,10 C1.15215669,10.4675212 1.5323684,10.8477603 1.99985598,10.8477603 C2.46734356,10.8477603 2.84784331,10.4675212 2.84784331,10 C2.84784331,9.53247876 2.46734356,9.15223967 1.99985598,9.15223967 Z"
            id="Shape"
            fill="#000000"
            fillRule="nonzero"
          />
          <path
            d="M14,16 C12.8973066,16 12,15.102758 12,14.000144 C12,12.897242 12.8973066,12 14,12 C15.1026934,12 16,12.897242 16,14.000144 C16,15.102758 15.1026934,16 14,16 Z M14,13.1521567 C13.5324788,13.1521567 13.1522397,13.5326564 13.1522397,14.000144 C13.1522397,14.4676316 13.5324788,14.8478433 14,14.8478433 C14.4675212,14.8478433 14.8477603,14.4676316 14.8477603,14.000144 C14.8477603,13.5326564 14.4675212,13.1521567 14,13.1521567 Z"
            id="Shape"
            fill="#000000"
            fillRule="nonzero"
          />
          <path
            d="M2,9 C1.448,9 1,8.65538462 1,8.23076923 L1,0.769230769 C1,0.344615385 1.448,0 2,0 C2.552,0 3,0.344615385 3,0.769230769 L3,8.23076923 C3,8.65538462 2.552,9 2,9 Z"
            id="Path"
            fill="#000000"
            fillRule="nonzero"
          />
          <path
            d="M8,5 C7.448,5 7,4.68501723 7,4.29691345 L7,0.70308655 C7,0.314982774 7.448,0 8,0 C8.552,0 9,0.314982774 9,0.70308655 L9,4.29691345 C9,4.68501723 8.552,5 8,5 Z"
            id="Path"
            fill="#000000"
            fillRule="nonzero"
          />
          <path
            d="M14,20 C13.448,20 13,19.5211629 13,18.9311672 L13,16.0688328 C13,15.4788371 13.448,15 14,15 C14.552,15 15,15.4788371 15,16.0688328 L15,18.9306327 C15,19.5211629 14.5525,20 14,20 Z"
            id="Path"
            fill="#000000"
            fillRule="nonzero"
          />
        </g>
      </g>
      <rect
        id="Rectangle"
        stroke="#00095B"
        x="0.5"
        y="0.5"
        width="25"
        height="25"
      />
    </g>
  </svg>
)
