import React, { forwardRef } from 'react'

export const Input = forwardRef(
  ({ value, placeholder, onChange, name, error }, ref) => (
    <>
      <input
        ref={ref}
        type="text"
        className={`custom-input ${error ? 'custom-input--error' : ''}`}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        name={name}
      />
      {error && <div className="custom-invalid-feedback">{error} </div>}
    </>
  )
)
