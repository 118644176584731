import React from 'react'
import PropTypes from 'prop-types'

export const CarStepSection = ({
  children,
  refProp,
  noBorder,
  noMarginTop,
  noMarginBottom
}) => {
  const borderTop = noBorder ? 'car-step-section--disabled-border' : ''
  const marginTop = noMarginTop ? 'car-step-section--disabled-marginTop' : ''
  const marginBottom = noMarginBottom
    ? 'car-step-section--disabled-marginBottom'
    : ''

  return (
    <div ref={refProp} className={`car-step-section ${borderTop} `}>
      <div className={`car-step-section__inner ${marginTop} ${marginBottom}`}>
        {children}
      </div>
    </div>
  )
}

CarStepSection.propTypes = {
  children: PropTypes.element.isRequired
}
