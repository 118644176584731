import React from 'react'
import { useSelector } from 'react-redux'
import { RadioButtonContainer } from './RadioButtonContainer'
import {
  getContractMileagesAllIds,
  getConfigurations
} from '../../../selectors'
import { Title } from '../components/Title'

export const ContractMileages = ({ translations }) => {
  const contractMileagesAllIds = useSelector(getContractMileagesAllIds)
  const configurations = useSelector(getConfigurations)
  const selectedContract = useSelector(
    state => state.carOptions.data.colors.byId
  )

  function getMileages() {
    const allMileagesList = []
    Object.keys(selectedContract).forEach(function(key) {
      selectedContract[key].prices.forEach(item => {
        if (item.duration === configurations.contractLengthSize) {
          allMileagesList.push(item)
        }
      })
    })

    // array has duplicates, we want unique list
    const uniqueMileagesList = allMileagesList
      .filter((v, i, a) => a.findIndex(t => t.mileage === v.mileage) === i)
      .sort((a, b) => a.mileage - b.mileage)

    return uniqueMileagesList
  }

  const radioButtonsJSX = contractMileagesAllIds.map(contractMileageId => (
    <RadioButtonContainer
      key={contractMileageId}
      id={contractMileageId}
      allowedMileagesList={getMileages()}
    />
  ))

  return (
    <div className="car-contract__mileage">
      <Title>{translations.Mileage.value}</Title>
      <div className="radio-button__container">{radioButtonsJSX}</div>
    </div>
  )
}
