export const getColorsAllIds = state => {
  return state.carOptions.data.colors.allIds
}

export const getColorsById = id => state => {
  return state.carOptions.data.colors.byId[id]
}

export const getSelectedColorId = state => {
  return state.carOptions.configurations.color
}

export const getContractLengthsAllIds = state => {
  return state.carOptions.data.contractLengths.allIds
}

export const getContractLengthsById = id => state => {
  return state.carOptions.data.contractLengths.byId[id]
}

export const getSelectedContractLength = state => {
  return state.carOptions.configurations.contractLength
}

export const getContractMileagesAllIds = state => {
  return state.carOptions.data.contractMileages.allIds
}

export const getContractMileagesById = id => state => {
  return state.carOptions.data.contractMileages.byId[id]
}

export const getSelectedContractMileage = state => {
  return state.carOptions.configurations.contractMileage
}

export const getOptionsAllIds = state => {
  return state.carOptions.data.options.allIds
}

export const getOptionsByIdData = state => {
  return state.carOptions.data.options.byId
}

export const getOptionsById = id => state => {
  return state.carOptions.data.options.byId[id]
}

export const getSelectedOptions = state => {
  return state.carOptions.configurations.options
}

export const getSelectedReadableOptions = state => {
  return state.carOptions.configurations.optionsReadableIds
}

export const getConfigurations = state => {
  return state.carOptions.configurations
}

export const getCarBasePrice = state => {
  return state.carOptions.configurations.carBasePrice
}

export const getCarId = state => {
  return state.carOptions.data.productId
}

export const getTotalPrice = state => {
  return state.carOptions.totalPrice
}

export const getColorProductId = state => {
  const colorId = state.carOptions.configurations.color

  if (colorId) {
    const color = state.carOptions.data.colors.byId[colorId]

    return color.productId
  }

  return ''
}

export const getContractLengthProductId = state => {
  const contractLengthId = state.carOptions.configurations.contractLength

  if (contractLengthId) {
    const contractLength =
      state.carOptions.data.contractLengths.byId[contractLengthId]

    return contractLength.productId
  }

  return ''
}

export const getContractMileageProductId = state => {
  const contractMileageId = state.carOptions.configurations.contractMileage

  if (contractMileageId) {
    const contractMileage =
      state.carOptions.data.contractMileages.byId[contractMileageId]

    return contractMileage.productId
  }

  return ''
}

export const getSelectedOptionsData = state => {
  const options = state.carOptions.configurations.options

  return options.map(optionId => {
    const { productId, variantId } = state.carOptions.data.options.byId[
      optionId
    ]

    return { productId, variantId }
  })
}

export const getUserMadeOrder = state => {
  return state.carOptions.userMadeOrder
}
