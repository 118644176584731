import React from 'react'
import { Factory } from './Factory'

const ImageTestsLinks = ({ fields }) => {
  return (
    <div className="image-text">
      <Factory {...fields} />
    </div>
  )
}

export default ImageTestsLinks
