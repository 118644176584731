import React from 'react'
import { Text } from '@sitecore-jss/sitecore-jss-react'
import { Header, Description, LinkButton } from '../components'

export const TextLinkComponent = ({
  alignText,
  alignLink,
  header,
  description,
  linkTitle
}) => {
  return (
    <div className="image-text__wrapper">
      <Header align={alignText}>
        <Text field={header} />
      </Header>
      <Description align={alignText}>
        <Text field={description} />
      </Description>
      {linkTitle.value.href ? (
        <LinkButton align={alignLink} title={linkTitle} />
      ) : null}
    </div>
  )
}
